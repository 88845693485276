@charset "UTF-8";
/* REVOLUTION STYLE SHEETS */
/*-----------------------------------------------------------------------------

-	Revolution Slider 5.0 Default Style Settings -

Screen Stylesheet

version:   	5.0.0
date:      	29/10/15
author:		themepunch
email:     	info@themepunch.com
website:   	http://www.themepunch.com
-----------------------------------------------------------------------------*/
#debungcontrolls {
  z-index: 100000;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  box-sizing: border-box; }

.debugtimeline {
  width: 100%;
  height: 10px;
  position: relative;
  display: block;
  margin-bottom: 3px;
  display: none;
  white-space: nowrap;
  box-sizing: border-box; }

.debugtimeline:hover {
  height: 15px; }

.the_timeline_tester {
  background: #e74c3c;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 0; }

.debugtimeline.tl_slide .the_timeline_tester {
  background: #f39c12; }

.debugtimeline.tl_frame .the_timeline_tester {
  background: #3498db; }

.debugtimline_txt {
  color: #fff;
  font-weight: 400;
  font-size: 7px;
  position: absolute;
  left: 10px;
  top: 0px;
  white-space: nowrap;
  line-height: 10px; }

.rtl {
  direction: rtl; }

@font-face {
  font-family: 'revicons';
  src: url("../fonts/revicons/revicons.eot?5510888");
  src: url("../fonts/revicons/revicons.eot?5510888#iefix") format("embedded-opentype"), url("../fonts/revicons/revicons.woff?5510888") format("woff"), url("../fonts/revicons/revicons.ttf?5510888") format("truetype"), url("../fonts/revicons/revicons.svg?5510888#revicons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="revicon-"]:before, [class*=" revicon-"]:before {
  font-family: "revicons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.revicon-search-1:before {
  content: '\e802'; }

/* '' */
.revicon-pencil-1:before {
  content: '\e831'; }

/* '' */
.revicon-picture-1:before {
  content: '\e803'; }

/* '' */
.revicon-cancel:before {
  content: '\e80a'; }

/* '' */
.revicon-info-circled:before {
  content: '\e80f'; }

/* '' */
.revicon-trash:before {
  content: '\e801'; }

/* '' */
.revicon-left-dir:before {
  content: '\e817'; }

/* '' */
.revicon-right-dir:before {
  content: '\e818'; }

/* '' */
.revicon-down-open:before {
  content: '\e83b'; }

/* '' */
.revicon-left-open:before {
  content: '\e819'; }

/* '' */
.revicon-right-open:before {
  content: '\e81a'; }

/* '' */
.revicon-angle-left:before {
  content: '\e820'; }

/* '' */
.revicon-angle-right:before {
  content: '\e81d'; }

/* '' */
.revicon-left-big:before {
  content: '\e81f'; }

/* '' */
.revicon-right-big:before {
  content: '\e81e'; }

/* '' */
.revicon-magic:before {
  content: '\e807'; }

/* '' */
.revicon-picture:before {
  content: '\e800'; }

/* '' */
.revicon-export:before {
  content: '\e80b'; }

/* '' */
.revicon-cog:before {
  content: '\e832'; }

/* '' */
.revicon-login:before {
  content: '\e833'; }

/* '' */
.revicon-logout:before {
  content: '\e834'; }

/* '' */
.revicon-video:before {
  content: '\e805'; }

/* '' */
.revicon-arrow-combo:before {
  content: '\e827'; }

/* '' */
.revicon-left-open-1:before {
  content: '\e82a'; }

/* '' */
.revicon-right-open-1:before {
  content: '\e82b'; }

/* '' */
.revicon-left-open-mini:before {
  content: '\e822'; }

/* '' */
.revicon-right-open-mini:before {
  content: '\e823'; }

/* '' */
.revicon-left-open-big:before {
  content: '\e824'; }

/* '' */
.revicon-right-open-big:before {
  content: '\e825'; }

/* '' */
.revicon-left:before {
  content: '\e836'; }

/* '' */
.revicon-right:before {
  content: '\e826'; }

/* '' */
.revicon-ccw:before {
  content: '\e808'; }

/* '' */
.revicon-arrows-ccw:before {
  content: '\e806'; }

/* '' */
.revicon-palette:before {
  content: '\e829'; }

/* '' */
.revicon-list-add:before {
  content: '\e80c'; }

/* '' */
.revicon-doc:before {
  content: '\e809'; }

/* '' */
.revicon-left-open-outline:before {
  content: '\e82e'; }

/* '' */
.revicon-left-open-2:before {
  content: '\e82c'; }

/* '' */
.revicon-right-open-outline:before {
  content: '\e82f'; }

/* '' */
.revicon-right-open-2:before {
  content: '\e82d'; }

/* '' */
.revicon-equalizer:before {
  content: '\e83a'; }

/* '' */
.revicon-layers-alt:before {
  content: '\e804'; }

/* '' */
.revicon-popup:before {
  content: '\e828'; }

/* '' */
/******************************
	-	BASIC STYLES		-
******************************/
.rev_slider_wrapper {
  position: relative;
  z-index: 0; }

.rev_slider {
  position: relative;
  overflow: visible; }

.tp-overflow-hidden {
  overflow: hidden !important; }

.group_ov_hidden {
  overflow: hidden; }

.tp-simpleresponsive img,
.rev_slider img {
  max-width: none !important;
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  margin: 0px;
  padding: 0px;
  border-width: 0px;
  border: none; }

.rev_slider .no-slides-text {
  font-weight: bold;
  text-align: center;
  padding-top: 80px; }

.rev_slider > ul,
.rev_slider_wrapper > ul,
.tp-revslider-mainul > li,
.rev_slider > ul > li,
.rev_slider > ul > li:before,
.tp-revslider-mainul > li:before,
.tp-simpleresponsive > ul,
.tp-simpleresponsive > ul > li,
.tp-simpleresponsive > ul > li:before,
.tp-revslider-mainul > li,
.tp-simpleresponsive > ul > li {
  list-style: none !important;
  position: absolute;
  margin: 0px !important;
  padding: 0px !important;
  overflow-x: visible;
  overflow-y: visible;
  list-style-type: none !important;
  background-image: none;
  background-position: 0px 0px;
  text-indent: 0em;
  top: 0px;
  left: 0px; }

.tp-revslider-mainul > li,
.rev_slider > ul > li,
.rev_slider > ul > li:before,
.tp-revslider-mainul > li:before,
.tp-simpleresponsive > ul > li,
.tp-simpleresponsive > ul > li:before,
.tp-revslider-mainul > li,
.tp-simpleresponsive > ul > li {
  visibility: hidden; }

.tp-revslider-slidesli,
.tp-revslider-mainul {
  padding: 0 !important;
  margin: 0 !important;
  list-style: none !important; }

.rev_slider li.tp-revslider-slidesli {
  position: absolute !important; }

.tp-caption .rs-untoggled-content {
  display: block; }

.tp-caption .rs-toggled-content {
  display: none; }

.rs-toggle-content-active.tp-caption .rs-toggled-content {
  display: block; }

.rs-toggle-content-active.tp-caption .rs-untoggled-content {
  display: none; }

.rev_slider .tp-caption,
.rev_slider .caption {
  position: relative;
  visibility: hidden;
  white-space: nowrap;
  display: block; }

.rev_slider .tp-mask-wrap .tp-caption,
.rev_slider .tp-mask-wrap *:last-child,
.wpb_text_column .rev_slider .tp-mask-wrap .tp-caption,
.wpb_text_column .rev_slider .tp-mask-wrap *:last-child {
  margin-bottom: 0; }

.tp-svg-layer svg {
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: top; }

/* CAROUSEL FUNCTIONS */
.tp-carousel-wrapper {
  cursor: url(openhand.cur), move; }

.tp-carousel-wrapper.dragged {
  cursor: url(closedhand.cur), move; }

/* ADDED FOR SLIDELINK MANAGEMENT */
.tp-caption {
  z-index: 1; }

.tp_inner_padding {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  max-height: none !important; }

.tp-caption {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  position: absolute;
  -webkit-font-smoothing: antialiased !important; }

.tp-caption.tp-layer-selectable {
  -moz-user-select: all;
  -khtml-user-select: all;
  -webkit-user-select: all;
  -o-user-select: all; }

.tp-forcenotvisible,
.tp-hide-revslider,
.tp-caption.tp-hidden-caption,
.tp-parallax-wrap.tp-hidden-caption {
  visibility: hidden !important;
  display: none !important; }

.rev_slider embed,
.rev_slider iframe,
.rev_slider object,
.rev_slider audio,
.rev_slider video {
  max-width: none !important; }

.tp-element-background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 0; }

/***********************************************************
	- 	ZONES / GOUP / ROW / COLUMN LAYERS AND HELPERS 	-
***********************************************************/
.rev_row_zone {
  position: absolute;
  width: 100%;
  left: 0px;
  box-sizing: border-box;
  min-height: 50px; }

.rev_row_zone_top {
  top: 0px; }

.rev_row_zone_middle {
  top: 50%;
  -webit-transform: translateY(-50%);
  transform: translateY(-50%); }

.rev_row_zone_bottom {
  bottom: 0px; }

.rev_column .tp-parallax-wrap {
  vertical-align: top; }

.rev_slider .tp-caption.rev_row {
  display: table;
  position: relative;
  width: 100% !important;
  table-layout: fixed;
  box-sizing: border-box;
  vertical-align: top;
  height: auto !important; }

.rev_column {
  display: table-cell;
  position: relative;
  vertical-align: top;
  height: auto;
  box-sizing: border-box; }

.rev_column_inner {
  box-sizing: border-box;
  display: block;
  position: relative;
  width: 100% !important;
  height: auto !important; }

.rev_column_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  box-sizing: border-box;
  background-clip: content-box;
  border: 0px solid transparent; }

.rev_column_inner .tp-parallax-wrap,
.rev_column_inner .tp-loop-wrap,
.rev_column_inner .tp-mask-wrap {
  text-align: inherit; }

.rev_column_inner .tp-mask-wrap {
  display: inline-block; }

.rev_column_inner .tp-parallax-wrap .tp-loop-wrap,
.rev_column_inner .tp-parallax-wrap .tp-mask-wrap,
.rev_column_inner .tp-parallax-wrap {
  position: relative !important;
  left: auto !important;
  top: auto !important;
  line-height: 0px; }

.rev_column_inner .tp-parallax-wrap .tp-loop-wrap,
.rev_column_inner .tp-parallax-wrap .tp-mask-wrap,
.rev_column_inner .tp-parallax-wrap,
.rev_column_inner .rev_layer_in_column {
  vertical-align: top; }

.rev_break_columns {
  display: block !important; }

.rev_break_columns .tp-parallax-wrap.rev_column {
  display: block !important;
  width: 100% !important; }

/**********************************************
	-	FULLSCREEN AND FULLWIDHT CONTAINERS	-
**********************************************/
.rev_slider_wrapper {
  width: 100%; }

.fullscreen-container {
  position: relative;
  padding: 0; }

.fullwidthbanner-container {
  position: relative;
  padding: 0;
  overflow: hidden; }

.fullwidthbanner-container .fullwidthabanner {
  width: 100%;
  position: relative; }

/*********************************
	-	SPECIAL TP CAPTIONS -
**********************************/
.tp-static-layers {
  position: absolute;
  z-index: 101;
  top: 0px;
  left: 0px;
  /*pointer-events:none;*/ }

.tp-caption .frontcorner {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 0px solid transparent;
  border-top: 40px solid #00A8FF;
  position: absolute;
  left: -40px;
  top: 0px; }

.tp-caption .backcorner {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 40px solid #00A8FF;
  position: absolute;
  right: 0px;
  top: 0px; }

.tp-caption .frontcornertop {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 40px solid #00A8FF;
  position: absolute;
  left: -40px;
  top: 0px; }

.tp-caption .backcornertop {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 40px solid transparent;
  border-top: 40px solid #00A8FF;
  position: absolute;
  right: 0px;
  top: 0px; }

.tp-layer-inner-rotation {
  position: relative !important; }

/***********************************************
	-	SPECIAL ALTERNATIVE IMAGE SETTINGS	-
***********************************************/
img.tp-slider-alternative-image {
  width: 100%;
  height: auto; }

/******************************
	-	IE8 HACKS	-
*******************************/
.noFilterClass {
  filter: none !important; }

/********************************
	-	FULLSCREEN VIDEO	-
*********************************/
.rs-background-video-layer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 0; }

.tp-caption.coverscreenvideo {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute; }

.caption.fullscreenvideo,
.tp-caption.fullscreenvideo {
  left: 0px;
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%; }

.caption.fullscreenvideo iframe,
.caption.fullscreenvideo audio,
.caption.fullscreenvideo video,
.tp-caption.fullscreenvideo iframe,
.tp-caption.fullscreenvideo iframe audio,
.tp-caption.fullscreenvideo iframe video {
  width: 100% !important;
  height: 100% !important;
  display: none; }

.fullcoveredvideo audio,
.fullscreenvideo audio
.fullcoveredvideo video,
.fullscreenvideo video {
  background: #000; }

.fullcoveredvideo .tp-poster {
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px; }

.videoisplaying .html5vid .tp-poster {
  display: none; }

.tp-video-play-button {
  background: #000;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #FFF;
  z-index: 3;
  margin-top: -25px;
  margin-left: -25px;
  line-height: 50px !important;
  text-align: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  z-index: 4;
  opacity: 0;
  -webkit-transition: opacity 300ms ease-out !important;
  -moz-transition: opacity 300ms ease-out !important;
  -o-transition: opacity 300ms ease-out !important;
  transition: opacity 300ms ease-out !important; }

.tp-hiddenaudio,
.tp-audio-html5 .tp-video-play-button {
  display: none !important; }

.tp-caption .html5vid {
  width: 100% !important;
  height: 100% !important; }

.tp-video-play-button i {
  width: 50px;
  height: 50px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  line-height: 50px !important;
  font-size: 40px !important; }

.tp-caption:hover .tp-video-play-button {
  opacity: 1; }

.tp-caption .tp-revstop {
  display: none;
  border-left: 5px solid #fff !important;
  border-right: 5px solid #fff !important;
  margin-top: 15px !important;
  line-height: 20px !important;
  vertical-align: top;
  font-size: 25px !important; }

.videoisplaying .revicon-right-dir {
  display: none; }

.videoisplaying .tp-revstop {
  display: inline-block; }

.videoisplaying .tp-video-play-button {
  display: none; }

.tp-caption:hover .tp-video-play-button {
  display: block; }

.fullcoveredvideo .tp-video-play-button {
  display: none !important; }

.fullscreenvideo .fullscreenvideo audio {
  object-fit: contain !important; }

.fullscreenvideo .fullscreenvideo video {
  object-fit: contain !important; }

.fullscreenvideo .fullcoveredvideo audio {
  object-fit: cover !important; }

.fullscreenvideo .fullcoveredvideo video {
  object-fit: cover !important; }

.tp-video-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  opacity: 0;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  -o-transition: opacity .3s;
  -ms-transition: opacity .3s;
  transition: opacity .3s;
  background-image: linear-gradient(to bottom, black 13%, #323232 100%);
  background-image: -o-linear-gradient(bottom, black 13%, #323232 100%);
  background-image: -moz-linear-gradient(bottom, black 13%, #323232 100%);
  background-image: -webkit-linear-gradient(bottom, black 13%, #323232 100%);
  background-image: -ms-linear-gradient(bottom, black 13%, #323232 100%);
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.13, black), color-stop(1, #323232));
  display: table;
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.tp-caption:hover .tp-video-controls {
  opacity: .9; }

.tp-video-button {
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  color: #EEE;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
  line-height: 12px;
  font-size: 12px;
  color: #fff;
  padding: 0px;
  margin: 0px;
  outline: none; }

.tp-video-button:hover {
  cursor: pointer; }

.tp-video-button-wrap,
.tp-video-seek-bar-wrap,
.tp-video-vol-bar-wrap {
  padding: 0px 5px;
  display: table-cell;
  vertical-align: middle; }

.tp-video-seek-bar-wrap {
  width: 80%; }

.tp-video-vol-bar-wrap {
  width: 20%; }

.tp-volume-bar,
.tp-seek-bar {
  width: 100%;
  cursor: pointer;
  outline: none;
  line-height: 12px;
  margin: 0;
  padding: 0; }

.rs-fullvideo-cover {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  background: transparent;
  z-index: 5; }

.rs-background-video-layer video::-webkit-media-controls {
  display: none !important; }

.rs-background-video-layer audio::-webkit-media-controls {
  display: none !important; }

.tp-audio-html5 .tp-video-controls {
  opacity: 1 !important;
  visibility: visible !important; }

.disabled_lc .tp-video-play-button {
  display: none !important; }

.disabled_lc .tp-video-play-button {
  display: none !important; }

/********************************
	-	DOTTED OVERLAYS	-
*********************************/
.tp-dottedoverlay {
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3; }

.tp-dottedoverlay.twoxtwo {
  background: url(../assets/gridtile.png); }

.tp-dottedoverlay.twoxtwowhite {
  background: url(../assets/gridtile_white.png); }

.tp-dottedoverlay.threexthree {
  background: url(../assets/gridtile_3x3.png); }

.tp-dottedoverlay.threexthreewhite {
  background: url(../assets/gridtile_3x3_white.png); }

/******************************
	-	SHADOWS		-
******************************/
.tp-shadowcover {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #fff;
  position: absolute;
  z-index: -1; }

.tp-shadow1 {
  -webkit-box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.8);
  box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.8); }

.tp-shadow2:before, .tp-shadow2:after,
.tp-shadow3:before, .tp-shadow4:after {
  z-index: -2;
  position: absolute;
  content: "";
  bottom: 10px;
  left: 10px;
  width: 50%;
  top: 85%;
  max-width: 300px;
  background: transparent;
  -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.8);
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.8);
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg); }

.tp-shadow2:after,
.tp-shadow4:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto; }

.tp-shadow5 {
  position: relative;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset; }

.tp-shadow5:before, .tp-shadow5:after {
  content: "";
  position: absolute;
  z-index: -2;
  -webkit-box-shadow: 0 0 25px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 25px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 25px 0px rgba(0, 0, 0, 0.6);
  top: 30%;
  bottom: 0;
  left: 20px;
  right: 20px;
  -moz-border-radius: 100px / 20px;
  border-radius: 100px / 20px; }

/******************************
	-	BUTTONS	-
*******************************/
.tp-button {
  padding: 6px 13px 5px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  height: 30px;
  cursor: pointer;
  color: #fff !important;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6) !important;
  font-size: 15px;
  line-height: 45px !important;
  font-family: arial, sans-serif;
  font-weight: bold;
  letter-spacing: -1px;
  text-decoration: none; }

.tp-button.big {
  color: #fff;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);
  font-weight: bold;
  padding: 9px 20px;
  font-size: 19px;
  line-height: 57px !important; }

.purchase:hover,
.tp-button:hover,
.tp-button.big:hover {
  background-position: bottom, 15px 11px; }

/*	BUTTON COLORS	*/
.tp-button.green, .tp-button:hover.green,
.purchase.green, .purchase:hover.green {
  background-color: #21a117;
  -webkit-box-shadow: 0px 3px 0px 0px #104d0b;
  -moz-box-shadow: 0px 3px 0px 0px #104d0b;
  box-shadow: 0px 3px 0px 0px #104d0b; }

.tp-button.blue, .tp-button:hover.blue,
.purchase.blue, .purchase:hover.blue {
  background-color: #1d78cb;
  -webkit-box-shadow: 0px 3px 0px 0px #0f3e68;
  -moz-box-shadow: 0px 3px 0px 0px #0f3e68;
  box-shadow: 0px 3px 0px 0px #0f3e68; }

.tp-button.red, .tp-button:hover.red,
.purchase.red, .purchase:hover.red {
  background-color: #cb1d1d;
  -webkit-box-shadow: 0px 3px 0px 0px #7c1212;
  -moz-box-shadow: 0px 3px 0px 0px #7c1212;
  box-shadow: 0px 3px 0px 0px #7c1212; }

.tp-button.orange, .tp-button:hover.orange,
.purchase.orange, .purchase:hover.orange {
  background-color: #ff7700;
  -webkit-box-shadow: 0px 3px 0px 0px #a34c00;
  -moz-box-shadow: 0px 3px 0px 0px #a34c00;
  box-shadow: 0px 3px 0px 0px #a34c00; }

.tp-button.darkgrey, .tp-button.grey,
.tp-button:hover.darkgrey, .tp-button:hover.grey,
.purchase.darkgrey, .purchase:hover.darkgrey {
  background-color: #555;
  -webkit-box-shadow: 0px 3px 0px 0px #222;
  -moz-box-shadow: 0px 3px 0px 0px #222;
  box-shadow: 0px 3px 0px 0px #222; }

.tp-button.lightgrey, .tp-button:hover.lightgrey,
.purchase.lightgrey, .purchase:hover.lightgrey {
  background-color: #888;
  -webkit-box-shadow: 0px 3px 0px 0px #555;
  -moz-box-shadow: 0px 3px 0px 0px #555;
  box-shadow: 0px 3px 0px 0px #555; }

/* TP BUTTONS DESKTOP SIZE */
.rev-btn,
.rev-btn:visited {
  outline: none !important;
  box-shadow: none !important;
  text-decoration: none !important;
  line-height: 44px;
  font-size: 17px;
  font-weight: 500;
  padding: 12px 35px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  cursor: pointer; }

.rev-btn.rev-uppercase,
.rev-btn.rev-uppercase:visited {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 900; }

.rev-btn.rev-withicon i {
  font-size: 15px;
  font-weight: normal;
  position: relative;
  top: 0px;
  -webkit-transition: all 0.2s ease-out !important;
  -moz-transition: all 0.2s ease-out !important;
  -o-transition: all 0.2s ease-out !important;
  -ms-transition: all 0.2s ease-out !important;
  margin-left: 10px !important; }

.rev-btn.rev-hiddenicon i {
  font-size: 15px;
  font-weight: normal;
  position: relative;
  top: 0px;
  -webkit-transition: all 0.2s ease-out !important;
  -moz-transition: all 0.2s ease-out !important;
  -o-transition: all 0.2s ease-out !important;
  -ms-transition: all 0.2s ease-out !important;
  opacity: 0;
  margin-left: 0px !important;
  width: 0px !important; }

.rev-btn.rev-hiddenicon:hover i {
  opacity: 1 !important;
  margin-left: 10px !important;
  width: auto !important; }

/* REV BUTTONS MEDIUM */
.rev-btn.rev-medium,
.rev-btn.rev-medium:visited {
  line-height: 36px;
  font-size: 14px;
  padding: 10px 30px; }

.rev-btn.rev-medium.rev-withicon i {
  font-size: 14px;
  top: 0px; }

.rev-btn.rev-medium.rev-hiddenicon i {
  font-size: 14px;
  top: 0px; }

/* REV BUTTONS SMALL */
.rev-btn.rev-small,
.rev-btn.rev-small:visited {
  line-height: 28px;
  font-size: 12px;
  padding: 7px 20px; }

.rev-btn.rev-small.rev-withicon i {
  font-size: 12px;
  top: 0px; }

.rev-btn.rev-small.rev-hiddenicon i {
  font-size: 12px;
  top: 0px; }

/* ROUNDING OPTIONS */
.rev-maxround {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px; }

.rev-minround {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

/* BURGER BUTTON */
.rev-burger {
  position: relative;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  padding: 22px 0 0 14px;
  border-radius: 50%;
  border: 1px solid rgba(51, 51, 51, 0.25);
  tap-highlight-color: transparent;
  cursor: pointer; }

.rev-burger span {
  display: block;
  width: 30px;
  height: 3px;
  background: #333;
  transition: .7s;
  pointer-events: none;
  transform-style: flat !important; }

.rev-burger span:nth-child(2) {
  margin: 3px 0; }

#dialog_addbutton .rev-burger:hover :first-child,
.open .rev-burger :first-child,
.open.rev-burger :first-child {
  transform: translateY(6px) rotate(-45deg);
  -webkit-transform: translateY(6px) rotate(-45deg); }

#dialog_addbutton .rev-burger:hover :nth-child(2),
.open .rev-burger :nth-child(2),
.open.rev-burger :nth-child(2) {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  opacity: 0; }

#dialog_addbutton .rev-burger:hover :last-child,
.open .rev-burger :last-child,
.open.rev-burger :last-child {
  transform: translateY(-6px) rotate(-135deg);
  -webkit-transform: translateY(-6px) rotate(-135deg); }

.rev-burger.revb-white {
  border: 2px solid rgba(255, 255, 255, 0.2); }

.rev-burger.revb-white span {
  background: #fff; }

.rev-burger.revb-whitenoborder {
  border: 0; }

.rev-burger.revb-whitenoborder span {
  background: #fff; }

.rev-burger.revb-darknoborder {
  border: 0; }

.rev-burger.revb-darknoborder span {
  background: #333; }

.rev-burger.revb-whitefull {
  background: #fff;
  border: none; }

.rev-burger.revb-whitefull span {
  background: #333; }

.rev-burger.revb-darkfull {
  background: #333;
  border: none; }

.rev-burger.revb-darkfull span {
  background: #fff; }

/* SCROLL DOWN BUTTON */
@-webkit-keyframes rev-ani-mouse {
  0% {
    opacity: 1;
    top: 29%; }
  15% {
    opacity: 1;
    top: 50%; }
  50% {
    opacity: 0;
    top: 50%; }
  100% {
    opacity: 0;
    top: 29%; } }

@-moz-keyframes rev-ani-mouse {
  0% {
    opacity: 1;
    top: 29%; }
  15% {
    opacity: 1;
    top: 50%; }
  50% {
    opacity: 0;
    top: 50%; }
  100% {
    opacity: 0;
    top: 29%; } }

@keyframes rev-ani-mouse {
  0% {
    opacity: 1;
    top: 29%; }
  15% {
    opacity: 1;
    top: 50%; }
  50% {
    opacity: 0;
    top: 50%; }
  100% {
    opacity: 0;
    top: 29%; } }

.rev-scroll-btn {
  display: inline-block;
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  cursor: pointer;
  width: 35px;
  height: 55px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid white;
  border-radius: 23px; }

.rev-scroll-btn > * {
  display: inline-block;
  line-height: 18px;
  font-size: 13px;
  font-weight: normal;
  color: #7f8c8d;
  color: #ffffff;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 2px; }

.rev-scroll-btn > *:hover,
.rev-scroll-btn > *:focus,
.rev-scroll-btn > *.active {
  color: #ffffff; }

.rev-scroll-btn > *:hover,
.rev-scroll-btn > *:focus,
.rev-scroll-btn > *:active,
.rev-scroll-btn > *.active {
  filter: alpha(opacity=80); }

.rev-scroll-btn.revs-fullwhite {
  background: #fff; }

.rev-scroll-btn.revs-fullwhite span {
  background: #333; }

.rev-scroll-btn.revs-fulldark {
  background: #333;
  border: none; }

.rev-scroll-btn.revs-fulldark span {
  background: #fff; }

.rev-scroll-btn span {
  position: absolute;
  display: block;
  top: 29%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  background: white;
  border-radius: 50%;
  -webkit-animation: rev-ani-mouse 2.5s linear infinite;
  -moz-animation: rev-ani-mouse 2.5s linear infinite;
  animation: rev-ani-mouse 2.5s linear infinite; }

.rev-scroll-btn.revs-dark {
  border-color: #333; }

.rev-scroll-btn.revs-dark span {
  background: #333; }

.rev-control-btn {
  position: relative;
  display: inline-block;
  z-index: 5;
  color: #FFF;
  font-size: 20px;
  line-height: 60px;
  font-weight: 400;
  font-style: normal;
  font-family: Raleway;
  text-decoration: none;
  text-align: center;
  background-color: #000;
  border-radius: 50px;
  text-shadow: none;
  background-color: rgba(0, 0, 0, 0.5);
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  cursor: pointer; }

.rev-cbutton-dark-sr {
  border-radius: 3px; }

.rev-cbutton-light {
  color: #333;
  background-color: rgba(255, 255, 255, 0.75); }

.rev-cbutton-light-sr {
  color: #333;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.75); }

.rev-sbutton {
  line-height: 37px;
  width: 37px;
  height: 37px; }

.rev-sbutton-blue {
  background-color: #3B5998; }

.rev-sbutton-lightblue {
  background-color: #00A0D1; }

.rev-sbutton-red {
  background-color: #DD4B39; }

/************************************
-	TP BANNER TIMER		-
*************************************/
.tp-bannertimer {
  visibility: hidden;
  width: 100%;
  height: 5px;
  /*background:url(../assets/timer.png);*/
  background: #fff;
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: 200;
  top: 0px; }

.tp-bannertimer.tp-bottom {
  top: auto;
  bottom: 0px !important;
  height: 5px; }

/*********************************************
-	BASIC SETTINGS FOR THE BANNER	-
***********************************************/
.tp-simpleresponsive img {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none; }

.tp-caption img {
  background: transparent;
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)";
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF);
  zoom: 1; }

/*  CAPTION SLIDELINK   **/
.caption.slidelink a div,
.tp-caption.slidelink a div {
  width: 3000px;
  height: 1500px;
  background: url(../assets/coloredbg.png) repeat; }

.tp-caption.slidelink a span {
  background: url(../assets/coloredbg.png) repeat; }

.tp-shape {
  width: 100%;
  height: 100%; }

/*********************************************
-	WOOCOMMERCE STYLES	-
***********************************************/
.tp-caption .rs-starring {
  display: inline-block; }

.tp-caption .rs-starring .star-rating {
  float: none; }

.tp-caption .rs-starring .star-rating {
  color: #FFC321 !important;
  display: inline-block;
  vertical-align: top; }

.tp-caption .rs-starring .star-rating,
.tp-caption .rs-starring-page .star-rating {
  position: relative;
  height: 1em;
  width: 5.4em;
  font-family: star; }

.tp-caption .rs-starring .star-rating:before,
.tp-caption .rs-starring-page .star-rating:before {
  content: "\73\73\73\73\73";
  color: #E0DADF;
  float: left;
  top: 0;
  left: 0;
  position: absolute; }

.tp-caption .rs-starring .star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
  font-size: 1em !important; }

.tp-caption .rs-starring .star-rating span:before,
.tp-caption .rs-starring .star-rating span:before {
  content: "\53\53\53\53\53";
  top: 0;
  position: absolute;
  left: 0; }

.tp-caption .rs-starring .star-rating {
  color: #FFC321 !important; }

.tp-caption .rs-starring .star-rating,
.tp-caption .rs-starring-page .star-rating {
  font-size: 1em !important;
  font-family: star; }

/******************************
	-	LOADER FORMS	-
********************************/
.tp-loader {
  top: 50%;
  left: 50%;
  z-index: 10000;
  position: absolute; }

.tp-loader.spinner0 {
  width: 40px;
  height: 40px;
  background-color: #fff;
  background-image: url(../assets/loader.gif);
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  margin-top: -20px;
  margin-left: -20px;
  -webkit-animation: tp-rotateplane 1.2s infinite ease-in-out;
  animation: tp-rotateplane 1.2s infinite ease-in-out;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px; }

.tp-loader.spinner1 {
  width: 40px;
  height: 40px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  margin-top: -20px;
  margin-left: -20px;
  -webkit-animation: tp-rotateplane 1.2s infinite ease-in-out;
  animation: tp-rotateplane 1.2s infinite ease-in-out;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px; }

.tp-loader.spinner5 {
  background-image: url(../assets/loader.gif);
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-color: #fff;
  margin: -22px -22px;
  width: 44px;
  height: 44px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px; }

@-webkit-keyframes tp-rotateplane {
  0% {
    -webkit-transform: perspective(120px); }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg); }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg); } }

@keyframes tp-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

.tp-loader.spinner2 {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  background-color: #ff0000;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  -webkit-animation: tp-scaleout 1.0s infinite ease-in-out;
  animation: tp-scaleout 1.0s infinite ease-in-out; }

@-webkit-keyframes tp-scaleout {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

@keyframes tp-scaleout {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0; } }

.tp-loader.spinner3 {
  margin: -9px 0px 0px -35px;
  width: 70px;
  text-align: center; }

.tp-loader.spinner3 .bounce1,
.tp-loader.spinner3 .bounce2,
.tp-loader.spinner3 .bounce3 {
  width: 18px;
  height: 18px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: tp-bouncedelay 1.4s infinite ease-in-out;
  animation: tp-bouncedelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.tp-loader.spinner3 .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.tp-loader.spinner3 .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes tp-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes tp-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

.tp-loader.spinner4 {
  margin: -20px 0px 0px -20px;
  width: 40px;
  height: 40px;
  text-align: center;
  -webkit-animation: tp-rotate 2.0s infinite linear;
  animation: tp-rotate 2.0s infinite linear; }

.tp-loader.spinner4 .dot1,
.tp-loader.spinner4 .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: tp-bounce 2.0s infinite ease-in-out;
  animation: tp-bounce 2.0s infinite ease-in-out;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15); }

.tp-loader.spinner4 .dot2 {
  top: auto;
  bottom: 0px;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

@-webkit-keyframes tp-rotate {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes tp-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes tp-bounce {
  0%, 100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes tp-bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

/***********************************************
	-  STANDARD NAVIGATION SETTINGS 
***********************************************/
.tp-thumbs.navbar,
.tp-bullets.navbar,
.tp-tabs.navbar {
  border: none;
  min-height: 0;
  margin: 0;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0; }

.tp-tabs,
.tp-thumbs,
.tp-bullets {
  position: absolute;
  display: block;
  z-index: 1000;
  top: 0px;
  left: 0px; }

.tp-tab,
.tp-thumb {
  cursor: pointer;
  position: absolute;
  opacity: 0.5;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.tp-arr-imgholder,
.tp-videoposter,
.tp-thumb-image,
.tp-tab-image {
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px; }

.tp-tab:hover,
.tp-tab.selected,
.tp-thumb:hover,
.tp-thumb.selected {
  opacity: 1; }

.tp-tab-mask,
.tp-thumb-mask {
  box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important; }

.tp-tabs,
.tp-thumbs {
  box-sizing: content-box !important;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important; }

.tp-bullet {
  width: 15px;
  height: 15px;
  position: absolute;
  background: #fff;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer; }

.tp-bullet.selected,
.tp-bullet:hover {
  background: #fff; }

.tp-bannertimer {
  background: #000;
  background: rgba(0, 0, 0, 0.15);
  height: 5px; }

.tparrows {
  cursor: pointer;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  position: absolute;
  display: block;
  z-index: 1000; }

.tparrows:hover {
  background: #000; }

.tparrows:before {
  font-family: "revicons";
  font-size: 15px;
  color: #fff;
  display: block;
  line-height: 40px;
  text-align: center; }

.tparrows.tp-leftarrow:before {
  content: '\e824'; }

.tparrows.tp-rightarrow:before {
  content: '\e825'; }

/***************************
	- KEN BURNS FIXES -
***************************/
body.rtl .tp-kbimg {
  left: 0 !important; }

/***************************
	- 3D SHADOW MODE -
***************************/
.dddwrappershadow {
  box-shadow: 0 45px 100px rgba(0, 0, 0, 0.4); }

/*******************
	- DEBUG MODE -
*******************/
.hglayerinfo {
  position: fixed;
  bottom: 0px;
  left: 0px;
  color: #FFF;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.75);
  padding: 5px 10px;
  z-index: 2000;
  white-space: normal; }

.hginfo {
  position: absolute;
  top: -2px;
  left: -2px;
  color: #e74c3c;
  font-size: 12px;
  font-weight: 600;
  background: #000;
  padding: 2px 5px; }

.indebugmode .tp-caption:hover {
  border: 1px dashed #c0392b !important; }

.helpgrid {
  border: 2px dashed #c0392b;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0; }

#revsliderlogloglog {
  padding: 15px;
  color: #fff;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 200px;
  height: 150px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100000;
  font-size: 10px;
  overflow: scroll; }

/** 
INSTAGRAM FILTERS BY UNA 
https://una.im/CSSgram/
**/
.aden {
  -webkit-filter: hue-rotate(-20deg) contrast(0.9) saturate(0.85) brightness(1.2);
  filter: hue-rotate(-20deg) contrast(0.9) saturate(0.85) brightness(1.2); }

.aden::after {
  background: -webkit-linear-gradient(left, rgba(66, 10, 14, 0.2), transparent);
  background: linear-gradient(to right, rgba(66, 10, 14, 0.2), transparent);
  mix-blend-mode: darken; }

.perpetua::after, .reyes::after {
  mix-blend-mode: soft-light;
  opacity: .5; }

.inkwell {
  -webkit-filter: sepia(0.3) contrast(1.1) brightness(1.1) grayscale(1);
  filter: sepia(0.3) contrast(1.1) brightness(1.1) grayscale(1); }

.perpetua::after {
  background: -webkit-linear-gradient(top, #005b9a, #e6c13d);
  background: linear-gradient(to bottom, #005b9a, #e6c13d); }

.reyes {
  -webkit-filter: sepia(0.22) brightness(1.1) contrast(0.85) saturate(0.75);
  filter: sepia(0.22) brightness(1.1) contrast(0.85) saturate(0.75); }

.reyes::after {
  background: #efcdad; }

.gingham {
  -webkit-filter: brightness(1.05) hue-rotate(-10deg);
  filter: brightness(1.05) hue-rotate(-10deg); }

.gingham::after {
  background: -webkit-linear-gradient(left, rgba(66, 10, 14, 0.2), transparent);
  background: linear-gradient(to right, rgba(66, 10, 14, 0.2), transparent);
  mix-blend-mode: darken; }

.toaster {
  -webkit-filter: contrast(1.5) brightness(0.9);
  filter: contrast(1.5) brightness(0.9); }

.toaster::after {
  background: -webkit-radial-gradient(circle, #804e0f, #3b003b);
  background: radial-gradient(circle, #804e0f, #3b003b);
  mix-blend-mode: screen; }

.walden {
  -webkit-filter: brightness(1.1) hue-rotate(-10deg) sepia(0.3) saturate(1.6);
  filter: brightness(1.1) hue-rotate(-10deg) sepia(0.3) saturate(1.6); }

.walden::after {
  background: #04c;
  mix-blend-mode: screen;
  opacity: .3; }

.hudson {
  -webkit-filter: brightness(1.2) contrast(0.9) saturate(1.1);
  filter: brightness(1.2) contrast(0.9) saturate(1.1); }

.hudson::after {
  background: -webkit-radial-gradient(circle, #a6b1ff 50%, #342134);
  background: radial-gradient(circle, #a6b1ff 50%, #342134);
  mix-blend-mode: multiply;
  opacity: .5; }

.earlybird {
  -webkit-filter: contrast(0.9) sepia(0.2);
  filter: contrast(0.9) sepia(0.2); }

.earlybird::after {
  background: -webkit-radial-gradient(circle, #d0ba8e 20%, #360309 85%, #1d0210 100%);
  background: radial-gradient(circle, #d0ba8e 20%, #360309 85%, #1d0210 100%);
  mix-blend-mode: overlay; }

.mayfair {
  -webkit-filter: contrast(1.1) saturate(1.1);
  filter: contrast(1.1) saturate(1.1); }

.mayfair::after {
  background: -webkit-radial-gradient(40% 40%, circle, rgba(255, 255, 255, 0.8), rgba(255, 200, 200, 0.6), #111 60%);
  background: radial-gradient(circle at 40% 40%, rgba(255, 255, 255, 0.8), rgba(255, 200, 200, 0.6), #111 60%);
  mix-blend-mode: overlay;
  opacity: .4; }

.lofi {
  -webkit-filter: saturate(1.1) contrast(1.5);
  filter: saturate(1.1) contrast(1.5); }

.lofi::after {
  background: -webkit-radial-gradient(circle, transparent 70%, #222 150%);
  background: radial-gradient(circle, transparent 70%, #222 150%);
  mix-blend-mode: multiply; }

._1977 {
  -webkit-filter: contrast(1.1) brightness(1.1) saturate(1.3);
  filter: contrast(1.1) brightness(1.1) saturate(1.3); }

._1977:after {
  background: rgba(243, 106, 188, 0.3);
  mix-blend-mode: screen; }

.brooklyn {
  -webkit-filter: contrast(0.9) brightness(1.1);
  filter: contrast(0.9) brightness(1.1); }

.brooklyn::after {
  background: -webkit-radial-gradient(circle, rgba(168, 223, 193, 0.4) 70%, #c4b7c8);
  background: radial-gradient(circle, rgba(168, 223, 193, 0.4) 70%, #c4b7c8);
  mix-blend-mode: overlay; }

.xpro2 {
  -webkit-filter: sepia(0.3);
  filter: sepia(0.3); }

.xpro2::after {
  background: -webkit-radial-gradient(circle, #e6e7e0 40%, rgba(43, 42, 161, 0.6) 110%);
  background: radial-gradient(circle, #e6e7e0 40%, rgba(43, 42, 161, 0.6) 110%);
  mix-blend-mode: color-burn; }

.nashville {
  -webkit-filter: sepia(0.2) contrast(1.2) brightness(1.05) saturate(1.2);
  filter: sepia(0.2) contrast(1.2) brightness(1.05) saturate(1.2); }

.nashville::after {
  background: rgba(0, 70, 150, 0.4);
  mix-blend-mode: lighten; }

.nashville::before {
  background: rgba(247, 176, 153, 0.56);
  mix-blend-mode: darken; }

.lark {
  -webkit-filter: contrast(0.9);
  filter: contrast(0.9); }

.lark::after {
  background: rgba(242, 242, 242, 0.8);
  mix-blend-mode: darken; }

.lark::before {
  background: #22253f;
  mix-blend-mode: color-dodge; }

.moon {
  -webkit-filter: grayscale(1) contrast(1.1) brightness(1.1);
  filter: grayscale(1) contrast(1.1) brightness(1.1); }

.moon::before {
  background: #a0a0a0;
  mix-blend-mode: soft-light; }

.moon::after {
  background: #383838;
  mix-blend-mode: lighten; }

.clarendon {
  -webkit-filter: contrast(1.2) saturate(1.35);
  filter: contrast(1.2) saturate(1.35); }

.clarendon:before {
  background: rgba(127, 187, 227, 0.2);
  mix-blend-mode: overlay; }

.willow {
  -webkit-filter: grayscale(0.5) contrast(0.95) brightness(0.9);
  filter: grayscale(0.5) contrast(0.95) brightness(0.9); }

.willow::before {
  background-color: radial-gradient(40%, circle, #d4a9af 55%, #000 150%);
  mix-blend-mode: overlay; }

.willow::after {
  background-color: #d8cdcb;
  mix-blend-mode: color; }

.rise {
  -webkit-filter: brightness(1.05) sepia(0.2) contrast(0.9) saturate(0.9);
  filter: brightness(1.05) sepia(0.2) contrast(0.9) saturate(0.9); }

.rise::after {
  background: -webkit-radial-gradient(circle, rgba(232, 197, 152, 0.8), transparent 90%);
  background: radial-gradient(circle, rgba(232, 197, 152, 0.8), transparent 90%);
  mix-blend-mode: overlay;
  opacity: .6; }

.rise::before {
  background: -webkit-radial-gradient(circle, rgba(236, 205, 169, 0.15) 55%, rgba(50, 30, 7, 0.4));
  background: radial-gradient(circle, rgba(236, 205, 169, 0.15) 55%, rgba(50, 30, 7, 0.4));
  mix-blend-mode: multiply; }

._1977:after, ._1977:before, .aden:after, .aden:before, .brooklyn:after, .brooklyn:before, .clarendon:after, .clarendon:before, .earlybird:after, .earlybird:before, .gingham:after, .gingham:before, .hudson:after, .hudson:before, .inkwell:after, .inkwell:before, .lark:after, .lark:before, .lofi:after, .lofi:before, .mayfair:after, .mayfair:before, .moon:after, .moon:before, .nashville:after, .nashville:before, .perpetua:after, .perpetua:before, .reyes:after, .reyes:before, .rise:after, .rise:before, .slumber:after, .slumber:before, .toaster:after, .toaster:before, .walden:after, .walden:before, .willow:after, .willow:before, .xpro2:after, .xpro2:before {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none; }

._1977, .aden, .brooklyn, .clarendon, .earlybird, .gingham, .hudson, .inkwell, .lark, .lofi, .mayfair, .moon, .nashville, .perpetua, .reyes, .rise, .slumber, .toaster, .walden, .willow, .xpro2 {
  position: relative; }

._1977 img, .aden img, .brooklyn img, .clarendon img, .earlybird img, .gingham img, .hudson img, .inkwell img, .lark img, .lofi img, .mayfair img, .moon img, .nashville img, .perpetua img, .reyes img, .rise img, .slumber img, .toaster img, .walden img, .willow img, .xpro2 img {
  width: 100%;
  z-index: 1; }

._1977:before, .aden:before, .brooklyn:before, .clarendon:before, .earlybird:before, .gingham:before, .hudson:before, .inkwell:before, .lark:before, .lofi:before, .mayfair:before, .moon:before, .nashville:before, .perpetua:before, .reyes:before, .rise:before, .slumber:before, .toaster:before, .walden:before, .willow:before, .xpro2:before {
  z-index: 2; }

._1977:after, .aden:after, .brooklyn:after, .clarendon:after, .earlybird:after, .gingham:after, .hudson:after, .inkwell:after, .lark:after, .lofi:after, .mayfair:after, .moon:after, .nashville:after, .perpetua:after, .reyes:after, .rise:after, .slumber:after, .toaster:after, .walden:after, .willow:after, .xpro2:after {
  z-index: 3; }

.slumber {
  -webkit-filter: saturate(0.66) brightness(1.05);
  filter: saturate(0.66) brightness(1.05); }

.slumber::after {
  background: rgba(125, 105, 24, 0.5);
  mix-blend-mode: soft-light; }

.slumber::before {
  background: rgba(69, 41, 12, 0.4);
  mix-blend-mode: lighten; }

/* @import url("../vendor/revolution/css/layers.css"); */
/*-----------------------------------------------------------------------------

-	Revolution Slider 5.0 Layer Style Settings -

Screen Stylesheet

version:   	5.0.0
date:      	18/03/15
author:		themepunch
email:     	info@themepunch.com
website:   	http://www.themepunch.com
-----------------------------------------------------------------------------*/
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.tp-caption.Twitter-Content a, .tp-caption.Twitter-Content a:visited {
  color: #0084B4 !important; }

.tp-caption.Twitter-Content a:hover {
  color: #0084B4 !important;
  text-decoration: underline !important; }

.tp-caption.medium_grey, .medium_grey {
  background-color: #888;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: Arial;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
  padding: 2px 4px;
  position: absolute;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  white-space: nowrap; }

.tp-caption.small_text, .small_text {
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  white-space: nowrap; }

.tp-caption.medium_text, .medium_text {
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: Arial;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  white-space: nowrap; }

.tp-caption.large_text, .large_text {
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: Arial;
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  margin: 0;
  position: absolute;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  white-space: nowrap; }

.tp-caption.very_large_text, .very_large_text {
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: Arial;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -2px;
  line-height: 60px;
  margin: 0;
  position: absolute;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  white-space: nowrap; }

.tp-caption.very_big_white, .very_big_white {
  background-color: #000;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: Arial;
  font-size: 60px;
  font-weight: 800;
  line-height: 60px;
  margin: 0;
  padding: 1px 4px 0;
  position: absolute;
  text-shadow: none;
  white-space: nowrap; }

.tp-caption.very_big_black, .very_big_black {
  background-color: #fff;
  border-style: none;
  border-width: 0;
  color: #000;
  font-family: Arial;
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  margin: 0;
  padding: 1px 4px 0;
  position: absolute;
  text-shadow: none;
  white-space: nowrap; }

.tp-caption.modern_medium_fat, .modern_medium_fat {
  border-style: none;
  border-width: 0;
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 800;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-shadow: none;
  white-space: nowrap; }

.tp-caption.modern_medium_fat_white, .modern_medium_fat_white {
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 800;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-shadow: none;
  white-space: nowrap; }

.tp-caption.modern_medium_light, .modern_medium_light {
  border-style: none;
  border-width: 0;
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-shadow: none;
  white-space: nowrap; }

.tp-caption.modern_big_bluebg, .modern_big_bluebg {
  background-color: #4e5b6c;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 36px;
  margin: 0;
  padding: 3px 10px;
  position: absolute;
  text-shadow: none; }

.tp-caption.modern_big_redbg, .modern_big_redbg {
  background-color: #de543e;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 36px;
  margin: 0;
  padding: 1px 10px 3px;
  position: absolute;
  text-shadow: none; }

.tp-caption.modern_small_text_dark, .modern_small_text_dark {
  border-style: none;
  border-width: 0;
  color: #555;
  font-family: Arial;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  position: absolute;
  text-shadow: none;
  white-space: nowrap; }

.tp-caption.boxshadow, .boxshadow {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }

.tp-caption.black, .black {
  color: #000;
  text-shadow: none; }

.tp-caption.noshadow, .noshadow {
  text-shadow: none; }

.tp-caption.thinheadline_dark, .thinheadline_dark {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.85);
  font-family: "Open Sans";
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  position: absolute;
  text-shadow: none; }

.tp-caption.thintext_dark, .thintext_dark {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.85);
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  position: absolute;
  text-shadow: none; }

.tp-caption.largeblackbg, .largeblackbg {
  background-color: #000;
  border-radius: 0;
  color: #fff;
  font-family: "Open Sans";
  font-size: 50px;
  font-weight: 300;
  line-height: 70px;
  padding: 0 20px;
  position: absolute;
  text-shadow: none; }

.tp-caption.largepinkbg, .largepinkbg {
  background-color: #db4360;
  border-radius: 0;
  color: #fff;
  font-family: "Open Sans";
  font-size: 50px;
  font-weight: 300;
  line-height: 70px;
  padding: 0 20px;
  position: absolute;
  text-shadow: none; }

.tp-caption.largewhitebg, .largewhitebg {
  background-color: #fff;
  border-radius: 0;
  color: #000;
  font-family: "Open Sans";
  font-size: 50px;
  font-weight: 300;
  line-height: 70px;
  padding: 0 20px;
  position: absolute;
  text-shadow: none; }

.tp-caption.largegreenbg, .largegreenbg {
  background-color: #67ae73;
  border-radius: 0;
  color: #fff;
  font-family: "Open Sans";
  font-size: 50px;
  font-weight: 300;
  line-height: 70px;
  padding: 0 20px;
  position: absolute;
  text-shadow: none; }

.tp-caption.excerpt, .excerpt {
  background-color: black;
  border-color: #fff;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: Arial;
  font-size: 36px;
  font-weight: 700;
  height: auto;
  letter-spacing: -1.5px;
  line-height: 36px;
  margin: 0;
  padding: 1px 4px 0;
  text-decoration: none;
  text-shadow: none;
  white-space: normal !important;
  width: 150px; }

.tp-caption.large_bold_grey, .large_bold_grey {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #666;
  font-family: "Open Sans";
  font-size: 60px;
  font-weight: 800;
  line-height: 60px;
  margin: 0;
  padding: 1px 4px 0;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.medium_thin_grey, .medium_thin_grey {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #666;
  font-family: "Open Sans";
  font-size: 34px;
  font-weight: 300;
  line-height: 30px;
  margin: 0;
  padding: 1px 4px 0;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.small_thin_grey, .small_thin_grey {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #757575;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  margin: 0;
  padding: 1px 4px 0;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.lightgrey_divider, .lightgrey_divider {
  background-color: #ebebeb;
  background-position: initial;
  background-repeat: initial;
  border-color: #222;
  border-style: none;
  border-width: 0;
  height: 3px;
  text-decoration: none;
  width: 370px; }

.tp-caption.large_bold_darkblue, .large_bold_darkblue {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #34495e;
  font-family: "Open Sans";
  font-size: 58px;
  font-weight: 800;
  line-height: 60px;
  text-decoration: none; }

.tp-caption.medium_bg_darkblue, .medium_bg_darkblue {
  background-color: #34495e;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
  padding: 10px;
  text-decoration: none; }

.tp-caption.medium_bold_red, .medium_bold_red {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #e33a0c;
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  padding: 0;
  text-decoration: none; }

.tp-caption.medium_light_red, .medium_light_red {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #e33a0c;
  font-family: "Open Sans";
  font-size: 21px;
  font-weight: 300;
  line-height: 26px;
  padding: 0;
  text-decoration: none; }

.tp-caption.medium_bg_red, .medium_bg_red {
  background-color: #e33a0c;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
  padding: 10px;
  text-decoration: none; }

.tp-caption.medium_bold_orange, .medium_bold_orange {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #f39c12;
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  text-decoration: none; }

.tp-caption.medium_bg_orange, .medium_bg_orange {
  background-color: #f39c12;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
  padding: 10px;
  text-decoration: none; }

.tp-caption.grassfloor, .grassfloor {
  background-color: #a0b397;
  border-color: #222;
  border-style: none;
  border-width: 0;
  height: 150px;
  text-decoration: none;
  width: 4000px; }

.tp-caption.large_bold_white, .large_bold_white {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Open Sans";
  font-size: 58px;
  font-weight: 800;
  line-height: 60px;
  text-decoration: none; }

.tp-caption.medium_light_white, .medium_light_white {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Open Sans";
  font-size: 30px;
  font-weight: 300;
  line-height: 36px;
  padding: 0;
  text-decoration: none; }

.tp-caption.mediumlarge_light_white, .mediumlarge_light_white {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Open Sans";
  font-size: 34px;
  font-weight: 300;
  line-height: 40px;
  padding: 0;
  text-decoration: none; }

.tp-caption.mediumlarge_light_white_center, .mediumlarge_light_white_center {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Open Sans";
  font-size: 34px;
  font-weight: 300;
  line-height: 40px;
  padding: 0;
  text-align: center;
  text-decoration: none; }

.tp-caption.medium_bg_asbestos, .medium_bg_asbestos {
  background-color: #7f8c8d;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
  padding: 10px;
  text-decoration: none; }

.tp-caption.medium_light_black, .medium_light_black {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #000;
  font-family: "Open Sans";
  font-size: 30px;
  font-weight: 300;
  line-height: 36px;
  padding: 0;
  text-decoration: none; }

.tp-caption.large_bold_black, .large_bold_black {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #000;
  font-family: "Open Sans";
  font-size: 58px;
  font-weight: 800;
  line-height: 60px;
  text-decoration: none; }

.tp-caption.mediumlarge_light_darkblue, .mediumlarge_light_darkblue {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #34495e;
  font-family: "Open Sans";
  font-size: 34px;
  font-weight: 300;
  line-height: 40px;
  padding: 0;
  text-decoration: none; }

.tp-caption.small_light_white, .small_light_white {
  background-color: transparent;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Open Sans";
  font-size: 17px;
  font-weight: 300;
  line-height: 28px;
  padding: 0;
  text-decoration: none; }

.tp-caption.roundedimage, .roundedimage {
  border-color: #222;
  border-style: none;
  border-width: 0; }

.tp-caption.large_bg_black, .large_bg_black {
  background-color: #000;
  border-color: #ffd658;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Open Sans";
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  padding: 10px 20px 15px;
  text-decoration: none; }

.tp-caption.mediumwhitebg, .mediumwhitebg {
  background-color: #fff;
  border-color: #000;
  border-style: none;
  border-width: 0;
  color: #000;
  font-family: "Open Sans";
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  padding: 5px 15px 10px;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.maincaption, .maincaption {
  background-color: transparent;
  border-color: #000;
  border-style: none;
  border-width: 0;
  color: #212a40;
  font-family: roboto;
  font-size: 33px;
  font-weight: 500;
  line-height: 43px;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.miami_title_60px, .miami_title_60px {
  background-color: transparent;
  border-color: #000;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Source Sans Pro";
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 60px;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.miami_subtitle, .miami_subtitle {
  background-color: transparent;
  border-color: #000;
  border-style: none;
  border-width: 0;
  color: rgba(255, 255, 255, 0.65);
  font-family: "Source Sans Pro";
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 24px;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.divideline30px, .divideline30px {
  background: #fff;
  background-color: #fff;
  border-color: #222;
  border-style: none;
  border-width: 0;
  height: 2px;
  min-width: 30px;
  text-decoration: none; }

.tp-caption.Miami_nostyle, .Miami_nostyle {
  border-color: #222;
  border-style: none;
  border-width: 0; }

.tp-caption.miami_content_light, .miami_content_light {
  background-color: transparent;
  border-color: #000;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Source Sans Pro";
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.miami_title_60px_dark, .miami_title_60px_dark {
  background-color: transparent;
  border-color: #000;
  border-style: none;
  border-width: 0;
  color: #333;
  font-family: "Source Sans Pro";
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 60px;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.miami_content_dark, .miami_content_dark {
  background-color: transparent;
  border-color: #000;
  border-style: none;
  border-width: 0;
  color: #666;
  font-family: "Source Sans Pro";
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.divideline30px_dark, .divideline30px_dark {
  background-color: #333;
  border-color: #222;
  border-style: none;
  border-width: 0;
  height: 2px;
  min-width: 30px;
  text-decoration: none; }

.tp-caption.ellipse70px, .ellipse70px {
  background-color: rgba(0, 0, 0, 0.14902);
  border-color: #222;
  border-radius: 50px 50px 50px 50px;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  line-height: 1px;
  min-height: 70px;
  min-width: 70px;
  text-decoration: none; }

.tp-caption.arrowicon, .arrowicon {
  border-color: #222;
  border-style: none;
  border-width: 0;
  line-height: 1px; }

.tp-caption.MarkerDisplay, .MarkerDisplay {
  background-color: transparent;
  border-color: #000;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  font-family: "Permanent Marker";
  font-style: normal;
  padding: 0;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.Restaurant-Display, .Restaurant-Display {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: Roboto;
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  line-height: 120px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Restaurant-Cursive, .Restaurant-Cursive {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: "Nothing you could do";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 30px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Restaurant-ScrollDownText, .Restaurant-ScrollDownText {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 17px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Restaurant-Description, .Restaurant-Description {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 3px;
  line-height: 30px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Restaurant-Price, .Restaurant-Price {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #fff;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 3px;
  line-height: 30px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Restaurant-Menuitem, .Restaurant-Menuitem {
  background-color: black;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 17px;
  padding: 10px 30px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Furniture-LogoText, .Furniture-LogoText {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #e6cfa3;
  font-family: 'Playfair Display', serif;
  font-size: 160px;
  font-style: normal;
  font-weight: 300;
  line-height: 150px;
  padding: 0;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.Furniture-Plus, .Furniture-Plus {
  background-color: white;
  border-color: transparent;
  border-radius: 30px 30px 30px 30px;
  border-style: none;
  border-width: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px;
  color: #e6cfa3;
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 6px 7px 4px;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.Furniture-Title, .Furniture-Title {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: black;
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 3px;
  line-height: 20px;
  padding: 0;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.Furniture-Subtitle, .Furniture-Subtitle {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: black;
  font-family: 'Playfair Display', serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  padding: 0;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.Gym-Display, .Gym-Display {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 900;
  line-height: 70px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Gym-Subline, .Gym-Subline {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 100;
  letter-spacing: 5px;
  line-height: 30px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Gym-SmallText, .Gym-SmallText {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 22;
  padding: 0;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.Fashion-SmallText, .Fashion-SmallText {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 20px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Fashion-BigDisplay, .Fashion-BigDisplay {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: black;
  font-family: 'Playfair Display', serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 2px;
  line-height: 60px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Fashion-TextBlock, .Fashion-TextBlock {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: black;
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 40px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Sports-Display, .Sports-Display {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 130px;
  font-style: normal;
  font-weight: 100;
  letter-spacing: 13px;
  line-height: 130px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Sports-DisplayFat, .Sports-DisplayFat {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 130px;
  font-style: normal;
  font-weight: 900;
  line-height: 130px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Sports-Subline, .Sports-Subline {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: black;
  font-family: 'Playfair Display', serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 4px;
  line-height: 32px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Instagram-Caption, .Instagram-Caption {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  padding: 0;
  text-decoration: none; }

.tp-caption.News-Title, .News-Title {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: "Roboto Slab";
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  padding: 0;
  text-decoration: none; }

.tp-caption.News-Subtitle, .News-Subtitle {
  background-color: rgba(255, 255, 255, 0);
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: "Roboto Slab";
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  padding: 0;
  text-decoration: none; }

.tp-caption.News-Subtitle:hover, .News-Subtitle:hover {
  background-color: rgba(255, 255, 255, 0);
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 0;
  color: rgba(255, 255, 255, 0.65);
  text-decoration: none; }

.tp-caption.Photography-Display, .Photography-Display {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 100;
  letter-spacing: 5px;
  line-height: 70px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Photography-Subline, .Photography-Subline {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #777777;
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 3px;
  line-height: 30px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Photography-ImageHover, .Photography-ImageHover {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0);
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22;
  padding: 0;
  text-decoration: none; }

.tp-caption.Photography-ImageHover:hover, .Photography-ImageHover:hover {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  text-decoration: none; }

.tp-caption.Photography-Menuitem, .Photography-Menuitem {
  background-color: rgba(0, 0, 0, 0.65);
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 20px;
  padding: 3px 5px 3px 8px;
  text-decoration: none; }

.tp-caption.Photography-Menuitem:hover, .Photography-Menuitem:hover {
  background-color: rgba(0, 255, 222, 0.65);
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  text-decoration: none; }

.tp-caption.Photography-Textblock, .Photography-Textblock {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 30px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Photography-Subline-2, .Photography-Subline-2 {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: rgba(255, 255, 255, 0.35);
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 3px;
  line-height: 30px;
  padding: 0;
  text-decoration: none; }

.tp-caption.Photography-ImageHover2, .Photography-ImageHover2 {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0);
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22;
  padding: 0;
  text-decoration: none; }

.tp-caption.Photography-ImageHover2:hover, .Photography-ImageHover2:hover {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  text-decoration: none; }

.tp-caption.WebProduct-Title, .WebProduct-Title {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #333333;
  font-family: 'Playfair Display', serif;
  font-size: 90px;
  font-style: normal;
  font-weight: 100;
  line-height: 90px;
  padding: 0;
  text-decoration: none; }

.tp-caption.WebProduct-SubTitle, .WebProduct-SubTitle {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #999999;
  font-family: 'Playfair Display', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0;
  text-decoration: none; }

.tp-caption.WebProduct-Content, .WebProduct-Content {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #999999;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 0;
  text-decoration: none; }

.tp-caption.WebProduct-Menuitem, .WebProduct-Menuitem {
  background-color: #333333;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 20px;
  padding: 3px 5px 3px 8px;
  text-align: left;
  text-decoration: none; }

.tp-caption.WebProduct-Menuitem:hover, .WebProduct-Menuitem:hover {
  background-color: white;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #999999;
  text-decoration: none; }

.tp-caption.WebProduct-Title-Light, .WebProduct-Title-Light {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 90px;
  font-style: normal;
  font-weight: 100;
  line-height: 90px;
  padding: 0;
  text-align: left;
  text-decoration: none; }

.tp-caption.WebProduct-SubTitle-Light, .WebProduct-SubTitle-Light {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: rgba(255, 255, 255, 0.35);
  font-family: 'Playfair Display', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0;
  text-align: left;
  text-decoration: none; }

.tp-caption.WebProduct-Content-Light, .WebProduct-Content-Light {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: rgba(255, 255, 255, 0.65);
  font-family: 'Playfair Display', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 0;
  text-align: left;
  text-decoration: none; }

.tp-caption.FatRounded, .FatRounded {
  background-color: rgba(0, 0, 0, 0.5);
  border-color: lightgray;
  border-radius: 50px 50px 50px 50px;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: 30px;
  padding: 20px 22px 20px 25px;
  text-align: left;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.FatRounded:hover, .FatRounded:hover {
  background-color: black;
  border-color: lightgray;
  border-radius: 50px 50px 50px 50px;
  border-style: none;
  border-width: 0;
  color: white;
  text-decoration: none; }

.tp-caption.NotGeneric-Title, .NotGeneric-Title {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  padding: 10px 0;
  text-decoration: none; }

.tp-caption.NotGeneric-SubTitle, .NotGeneric-SubTitle {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Lato', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 4px;
  line-height: 20px;
  padding: 0;
  text-align: left;
  text-decoration: none; }

.tp-caption.NotGeneric-CallToAction, .NotGeneric-CallToAction {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.5);
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 1px;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 3px;
  line-height: 14px;
  padding: 10px 30px;
  text-align: left;
  text-decoration: none; }

.tp-caption.NotGeneric-CallToAction:hover, .NotGeneric-CallToAction:hover {
  background-color: transparent;
  border-color: white;
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 1px;
  color: white;
  text-decoration: none; }

.tp-caption.NotGeneric-Icon, .NotGeneric-Icon {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0);
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 3px;
  line-height: 55px;
  padding: 0;
  text-align: left;
  text-decoration: none; }

.tp-caption.NotGeneric-Menuitem, .NotGeneric-Menuitem {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.15);
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 1px;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 3px;
  line-height: 14px;
  padding: 27px 30px;
  text-align: left;
  text-decoration: none; }

.tp-caption.NotGeneric-Menuitem:hover, .NotGeneric-Menuitem:hover {
  background-color: rgba(0, 0, 0, 0);
  border-color: white;
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 1px;
  color: white;
  text-decoration: none; }

.tp-caption.MarkerStyle, .MarkerStyle {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: "Permanent Marker";
  font-size: 17px;
  font-style: normal;
  font-weight: 100;
  line-height: 30px;
  padding: 0;
  text-align: left;
  text-decoration: none; }

.tp-caption.Gym-Menuitem, .Gym-Menuitem {
  background-color: black;
  border-color: rgba(255, 255, 255, 0);
  border-radius: 3px 3px 3px 3px;
  border-style: solid;
  border-width: 2px;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 20px;
  padding: 3px 5px 3px 8px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Gym-Menuitem:hover, .Gym-Menuitem:hover {
  background-color: black;
  border-color: rgba(255, 255, 255, 0.25);
  border-radius: 3px 3px 3px 3px;
  border-style: solid;
  border-width: 2px;
  color: white;
  text-decoration: none; }

.tp-caption.Newspaper-Button, .Newspaper-Button {
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0.25);
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 1px;
  color: white;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 17px;
  padding: 12px 35px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Newspaper-Button:hover, .Newspaper-Button:hover {
  background-color: white;
  border-color: white;
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 1px;
  color: black;
  text-decoration: none; }

.tp-caption.Newspaper-Subtitle, .Newspaper-Subtitle {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #a8d8ee;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  padding: 0;
  text-align: left;
  text-decoration: none; }

.tp-caption.Newspaper-Title, .Newspaper-Title {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: "Roboto Slab";
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 55px;
  padding: 0 0 10px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Newspaper-Title-Centered, .Newspaper-Title-Centered {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: "Roboto Slab";
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 55px;
  padding: 0 0 10px;
  text-align: center;
  text-decoration: none; }

.tp-caption.Hero-Button, .Hero-Button {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.5);
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 1px;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 3px;
  line-height: 14px;
  padding: 10px 30px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Hero-Button:hover, .Hero-Button:hover {
  background-color: white;
  border-color: white;
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 1px;
  color: black;
  text-decoration: none; }

.tp-caption.Video-Title, .Video-Title {
  background-color: black;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: 30px;
  padding: 5px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Video-SubTitle, .Video-SubTitle {
  background-color: rgba(0, 0, 0, 0.35);
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 12px;
  padding: 5px;
  text-align: left;
  text-decoration: none; }

.tp-caption.NotGeneric-Button, .NotGeneric-Button {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.5);
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 1px;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 3px;
  line-height: 14px;
  padding: 10px 30px;
  text-align: left;
  text-decoration: none; }

.tp-caption.NotGeneric-Button:hover, .NotGeneric-Button:hover {
  background-color: transparent;
  border-color: white;
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 1px;
  color: white;
  text-decoration: none; }

.tp-caption.NotGeneric-BigButton, .NotGeneric-BigButton {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.15);
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 1px;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 3px;
  line-height: 14px;
  padding: 27px 30px;
  text-align: left;
  text-decoration: none; }

.tp-caption.NotGeneric-BigButton:hover, .NotGeneric-BigButton:hover {
  background-color: rgba(0, 0, 0, 0);
  border-color: white;
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 1px;
  color: white;
  text-decoration: none; }

.tp-caption.WebProduct-Button, .WebProduct-Button {
  background-color: #333333;
  border-color: black;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 2px;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 48px;
  padding: 0 40px;
  text-align: left;
  text-decoration: none; }

.tp-caption.WebProduct-Button:hover, .WebProduct-Button:hover {
  background-color: white;
  border-color: black;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 2px;
  color: #333333;
  text-decoration: none; }

.tp-caption.Restaurant-Button, .Restaurant-Button {
  background-color: rgba(10, 10, 10, 0);
  border-color: rgba(255, 255, 255, 0.5);
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 2px;
  color: white;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 3px;
  line-height: 17px;
  padding: 12px 35px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Restaurant-Button:hover, .Restaurant-Button:hover {
  background-color: rgba(0, 0, 0, 0);
  border-color: #ffe081;
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 2px;
  color: white;
  text-decoration: none; }

.tp-caption.Gym-Button, .Gym-Button {
  background-color: #8bc027;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 0;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 15px;
  padding: 13px 35px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Gym-Button:hover, .Gym-Button:hover {
  background-color: #72a800;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 0;
  color: white;
  text-decoration: none; }

.tp-caption.Gym-Button-Light, .Gym-Button-Light {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.25);
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 2px;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  padding: 12px 35px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Gym-Button-Light:hover, .Gym-Button-Light:hover {
  background-color: rgba(114, 168, 0, 0);
  border-color: #8bc027;
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 2px;
  color: white;
  text-decoration: none; }

.tp-caption.Sports-Button-Light, .Sports-Button-Light {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.5);
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 2px;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 17px;
  padding: 12px 35px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Sports-Button-Light:hover, .Sports-Button-Light:hover {
  background-color: rgba(0, 0, 0, 0);
  border-color: white;
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 2px;
  color: white;
  text-decoration: none; }

.tp-caption.Sports-Button-Red, .Sports-Button-Red {
  background-color: #db1c22;
  border-color: rgba(219, 28, 34, 0);
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 2px;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 17px;
  padding: 12px 35px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Sports-Button-Red:hover, .Sports-Button-Red:hover {
  background-color: black;
  border-color: black;
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 2px;
  color: white;
  text-decoration: none; }

.tp-caption.Photography-Button, .Photography-Button {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.25);
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 1px;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 15px;
  padding: 13px 35px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Photography-Button:hover, .Photography-Button:hover {
  background-color: rgba(0, 0, 0, 0);
  border-color: white;
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 1px;
  color: white;
  text-decoration: none; }

.tp-caption.Newspaper-Button-2, .Newspaper-Button-2 {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.5);
  border-radius: 3px 3px 3px 3px;
  border-style: solid;
  border-width: 2px;
  color: white;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: 15px;
  padding: 10px 30px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Newspaper-Button-2:hover, .Newspaper-Button-2:hover {
  background-color: rgba(0, 0, 0, 0);
  border-color: white;
  border-radius: 3px 3px 3px 3px;
  border-style: solid;
  border-width: 2px;
  color: white;
  text-decoration: none; }

.tp-caption.Feature-Tour, .Feature-Tour {
  background-color: #8bc027;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 0;
  color: white;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  padding: 17px 35px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Feature-Tour:hover, .Feature-Tour:hover {
  background-color: #72a800;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 0;
  color: white;
  text-decoration: none; }

.tp-caption.Feature-Examples, .Feature-Examples {
  background-color: transparent;
  border-color: rgba(33, 42, 64, 0.15);
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 2px;
  color: rgba(33, 42, 64, 0.5);
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  padding: 15px 35px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Feature-Examples:hover, .Feature-Examples:hover {
  background-color: transparent;
  border-color: #8bc027;
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 2px;
  color: #8bc027;
  text-decoration: none; }

.tp-caption.subcaption, .subcaption {
  background-color: transparent;
  border-color: black;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #6f7c82;
  font-family: roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.menutab, .menutab {
  background-color: transparent;
  border-color: black;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #292e31;
  font-family: roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.menutab:hover, .menutab:hover {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #d50000;
  text-decoration: none; }

.tp-caption.maincontent, .maincontent {
  background-color: transparent;
  border-color: black;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #292e31;
  font-family: roboto;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.minitext, .minitext {
  background-color: transparent;
  border-color: black;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #b9babb;
  font-family: roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-shadow: none; }

.tp-caption.Feature-Buy, .Feature-Buy {
  background-color: #009aee;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 0;
  color: white;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  padding: 17px 35px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Feature-Buy:hover, .Feature-Buy:hover {
  background-color: #0085d6;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 0;
  color: white;
  text-decoration: none; }

.tp-caption.Feature-Examples-Light, .Feature-Examples-Light {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.15);
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 2px;
  color: white;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  padding: 15px 35px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Feature-Examples-Light:hover, .Feature-Examples-Light:hover {
  background-color: transparent;
  border-color: white;
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 2px;
  color: white;
  text-decoration: none; }

.tp-caption.Facebook-Likes, .Facebook-Likes {
  background-color: #3b5999;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  padding: 5px 15px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Twitter-Favorites, .Twitter-Favorites {
  background-color: rgba(255, 255, 255, 0);
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #8899a6;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  padding: 0;
  text-align: left;
  text-decoration: none; }

.tp-caption.Twitter-Link, .Twitter-Link {
  background-color: white;
  border-color: transparent;
  border-radius: 30px 30px 30px 30px;
  border-style: none;
  border-width: 0;
  color: #8799a5;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  padding: 11px 11px 9px;
  text-align: left;
  text-decoration: none; }

.tp-caption.Twitter-Link:hover, .Twitter-Link:hover {
  background-color: #0084b4;
  border-color: transparent;
  border-radius: 30px 30px 30px 30px;
  border-style: none;
  border-width: 0;
  color: white;
  text-decoration: none; }

.tp-caption.Twitter-Retweet, .Twitter-Retweet {
  background-color: rgba(255, 255, 255, 0);
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #8899a6;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  padding: 0;
  text-align: left;
  text-decoration: none; }

.tp-caption.Twitter-Content, .Twitter-Content {
  background-color: white;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #292f33;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding: 30px 30px 70px;
  text-align: left;
  text-decoration: none; }

.revtp-searchform input[type="text"],
.revtp-searchform input[type="email"],
.revtp-form input[type="text"],
.revtp-form input[type="email"] {
  font-family: "Arial", sans-serif;
  font-size: 15px;
  color: #000;
  background-color: #fff;
  line-height: 46px;
  padding: 0 20px;
  cursor: text;
  border: 0;
  width: 400px;
  margin-bottom: 0px;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  transition: background-color 0.5s;
  border-radius: 0px; }

.tp-caption.BigBold-Title,
.BigBold-Title {
  color: white;
  font-size: 110px;
  line-height: 100px;
  font-weight: 800;
  font-style: normal;
  font-family: Raleway;
  padding: 10px 0px 10px 0;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.BigBold-SubTitle,
.BigBold-SubTitle {
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  font-family: Raleway;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left;
  letter-spacing: 1px; }

.tp-caption.BigBold-Button,
.BigBold-Button {
  color: white;
  font-size: 13px;
  line-height: 13px;
  font-weight: 500;
  font-style: normal;
  font-family: Raleway;
  padding: 15px 50px 15px 50px;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 0px 0px;
  text-align: left;
  letter-spacing: 1px; }

.tp-caption.BigBold-Button:hover,
.BigBold-Button:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.FoodCarousel-Content,
.FoodCarousel-Content {
  color: #292e31;
  font-size: 17px;
  line-height: 28px;
  font-weight: 500;
  font-style: normal;
  font-family: Raleway;
  padding: 30px 30px 30px 30px;
  text-decoration: none;
  background-color: white;
  border-color: #292e31;
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.FoodCarousel-Button,
.FoodCarousel-Button {
  color: #292e31;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
  font-style: normal;
  font-family: Raleway;
  padding: 15px 70px 15px 50px;
  text-decoration: none;
  background-color: white;
  border-color: #292e31;
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 0px 0px;
  text-align: left;
  letter-spacing: 1px; }

.tp-caption.FoodCarousel-Button:hover,
.FoodCarousel-Button:hover {
  color: white;
  text-decoration: none;
  background-color: #292e31;
  border-color: #292e31;
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.FoodCarousel-CloseButton,
.FoodCarousel-CloseButton {
  color: black;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
  font-family: Raleway;
  padding: 14px 14px 14px 16px;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(41, 46, 49, 0);
  border-style: solid;
  border-width: 1px;
  border-radius: 30px 30px 30px 30px;
  text-align: left;
  letter-spacing: 1px; }

.tp-caption.FoodCarousel-CloseButton:hover,
.FoodCarousel-CloseButton:hover {
  color: white;
  text-decoration: none;
  background-color: #292e31;
  border-color: rgba(41, 46, 49, 0);
  border-style: solid;
  border-width: 1px;
  border-radius: 30px 30px 30px 30px; }

.tp-caption.Video-SubTitle,
.Video-SubTitle {
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  font-style: normal;
  font-family: Raleway;
  padding: 5px 5px 5px 5px;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.35);
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  letter-spacing: 2px;
  text-align: left; }

.tp-caption.Video-Title,
.Video-Title {
  color: white;
  font-size: 30px;
  line-height: 30px;
  font-weight: 900;
  font-style: normal;
  font-family: Raleway;
  padding: 5px 5px 5px 5px;
  text-decoration: none;
  background-color: black;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.Travel-BigCaption,
.Travel-BigCaption {
  color: white;
  font-size: 50px;
  line-height: 50px;
  font-weight: 400;
  font-style: normal;
  font-family: Roboto;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.Travel-SmallCaption,
.Travel-SmallCaption {
  color: white;
  font-size: 25px;
  line-height: 30px;
  font-weight: 300;
  font-style: normal;
  font-family: Roboto;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.Travel-CallToAction,
.Travel-CallToAction {
  color: white;
  font-size: 25px;
  line-height: 25px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 12px 20px 12px 20px;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.05);
  border-color: white;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px 5px 5px 5px;
  text-align: left;
  letter-spacing: 1px; }

.tp-caption.Travel-CallToAction:hover,
.Travel-CallToAction:hover {
  color: white;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.15);
  border-color: white;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px 5px 5px 5px; }

.tp-caption.RotatingWords-TitleWhite,
.RotatingWords-TitleWhite {
  color: white;
  font-size: 70px;
  line-height: 70px;
  font-weight: 800;
  font-style: normal;
  font-family: Raleway;
  padding: 0px 0px 0px 0;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.RotatingWords-Button,
.RotatingWords-Button {
  color: white;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
  font-family: Raleway;
  padding: 20px 50px 20px 50px;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.15);
  border-style: solid;
  border-width: 2px;
  border-radius: 0px 0px 0px 0px;
  text-align: left;
  letter-spacing: 3px; }

.tp-caption.RotatingWords-Button:hover,
.RotatingWords-Button:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 2px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.RotatingWords-SmallText,
.RotatingWords-SmallText {
  color: white;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  font-family: Raleway;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left;
  text-shadow: none; }

.tp-caption.ContentZoom-SmallTitle,
.ContentZoom-SmallTitle {
  color: #292e31;
  font-size: 33px;
  line-height: 45px;
  font-weight: 600;
  font-style: normal;
  font-family: Raleway;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.ContentZoom-SmallSubtitle,
.ContentZoom-SmallSubtitle {
  color: #6f7c82;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-style: normal;
  font-family: Raleway;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.ContentZoom-SmallIcon,
.ContentZoom-SmallIcon {
  color: #292e31;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  font-family: Raleway;
  padding: 10px 10px 10px 10px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.ContentZoom-SmallIcon:hover,
.ContentZoom-SmallIcon:hover {
  color: #6f7c82;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px; }

.tp-caption.ContentZoom-DetailTitle,
.ContentZoom-DetailTitle {
  color: #292e31;
  font-size: 70px;
  line-height: 70px;
  font-weight: 500;
  font-style: normal;
  font-family: Raleway;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.ContentZoom-DetailSubTitle,
.ContentZoom-DetailSubTitle {
  color: #6f7c82;
  font-size: 25px;
  line-height: 25px;
  font-weight: 500;
  font-style: normal;
  font-family: Raleway;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.ContentZoom-DetailContent,
.ContentZoom-DetailContent {
  color: #6f7c82;
  font-size: 17px;
  line-height: 28px;
  font-weight: 500;
  font-style: normal;
  font-family: Raleway;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.ContentZoom-Button,
.ContentZoom-Button {
  color: #292e31;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
  font-style: normal;
  font-family: Raleway;
  padding: 15px 50px 15px 50px;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(41, 46, 49, 0.5);
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 0px 0px;
  text-align: left;
  letter-spacing: 1px; }

.tp-caption.ContentZoom-Button:hover,
.ContentZoom-Button:hover {
  color: white;
  text-decoration: none;
  background-color: #292e31;
  border-color: #292e31;
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.ContentZoom-ButtonClose,
.ContentZoom-ButtonClose {
  color: #292e31;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
  font-style: normal;
  font-family: Raleway;
  padding: 14px 14px 14px 16px;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(41, 46, 49, 0.5);
  border-style: solid;
  border-width: 1px;
  border-radius: 30px 30px 30px 30px;
  text-align: left;
  letter-spacing: 1px; }

.tp-caption.ContentZoom-ButtonClose:hover,
.ContentZoom-ButtonClose:hover {
  color: white;
  text-decoration: none;
  background-color: #292e31;
  border-color: #292e31;
  border-style: solid;
  border-width: 1px;
  border-radius: 30px 30px 30px 30px; }

.tp-caption.Newspaper-Title,
.Newspaper-Title {
  color: white;
  font-size: 50px;
  line-height: 55px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto Slab";
  padding: 0 0 10px 0;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.Newspaper-Subtitle,
.Newspaper-Subtitle {
  color: #a8d8ee;
  font-size: 15px;
  line-height: 20px;
  font-weight: 900;
  font-style: normal;
  font-family: Roboto;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.Newspaper-Button,
.Newspaper-Button {
  color: white;
  font-size: 13px;
  line-height: 17px;
  font-weight: 700;
  font-style: normal;
  font-family: Roboto;
  padding: 12px 35px 12px 35px;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0.25);
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 0px 0px;
  letter-spacing: 2px;
  text-align: left; }

.tp-caption.Newspaper-Button:hover,
.Newspaper-Button:hover {
  color: black;
  text-decoration: none;
  background-color: white;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.rtwhitemedium,
.rtwhitemedium {
  font-size: 22px;
  line-height: 26px;
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-width: 0px;
  border-color: black;
  border-style: none;
  text-shadow: none; }

@media only screen and (max-width: 767px) {
  .revtp-searchform input[type="text"],
  .revtp-searchform input[type="email"],
  .revtp-form input[type="text"],
  .revtp-form input[type="email"] {
    width: 200px !important; } }

.revtp-searchform input[type="submit"],
.revtp-form input[type="submit"] {
  font-family: "Arial", sans-serif;
  line-height: 46px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  padding: 0 20px;
  border: 0;
  background: #009aee;
  color: #fff;
  border-radius: 0px; }

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
  color: #0084B4 !important; }

.tp-caption.Twitter-Content a:hover {
  color: #0084B4 !important;
  text-decoration: underline !important; }

.tp-caption.Concept-Title,
.Concept-Title {
  color: white;
  font-size: 70px;
  line-height: 70px;
  font-weight: 700;
  font-style: normal;
  font-family: "Roboto Condensed";
  padding: 0px 0px 10px 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px;
  letter-spacing: 5px; }

.tp-caption.Concept-SubTitle,
.Concept-SubTitle {
  color: rgba(255, 255, 255, 0.65);
  font-size: 25px;
  line-height: 25px;
  font-weight: 700;
  font-style: italic;
  font-family: "" Playfair Display "";
  padding: 0px 0px 10px 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.Concept-Content,
.Concept-Content {
  color: white;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto Condensed";
  padding: 0px 0px 0px 0px;
  text-decoration: none;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border-color: white;
  border-style: none;
  border-width: 2px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.Concept-MoreBtn,
.Concept-MoreBtn {
  color: white;
  font-size: 30px;
  line-height: 30px;
  font-weight: 300;
  font-style: normal;
  font-family: Roboto;
  padding: 10px 8px 7px 10px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border-width: 0px;
  border-radius: 50px 50px 50px 50px;
  letter-spacing: 1px;
  text-align: left; }

.tp-caption.Concept-MoreBtn:hover,
.Concept-MoreBtn:hover {
  color: white;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border-width: 0px;
  border-radius: 50px 50px 50px 50px; }

.tp-caption.Concept-LessBtn,
.Concept-LessBtn {
  color: white;
  font-size: 30px;
  line-height: 30px;
  font-weight: 300;
  font-style: normal;
  font-family: Roboto;
  padding: 10px 8px 7px 10px;
  text-decoration: none;
  text-align: left;
  background-color: black;
  border-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border-width: 0px;
  border-radius: 50px 50px 50px 50px;
  letter-spacing: 1px;
  text-align: left; }

.tp-caption.Concept-LessBtn:hover,
.Concept-LessBtn:hover {
  color: black;
  text-decoration: none;
  background-color: white;
  border-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border-width: 0px;
  border-radius: 50px 50px 50px 50px; }

.tp-caption.Concept-SubTitle-Dark,
.Concept-SubTitle-Dark {
  color: rgba(0, 0, 0, 0.65);
  font-size: 25px;
  line-height: 25px;
  font-weight: 700;
  font-style: italic;
  font-family: "Playfair Display";
  padding: 0px 0px 10px 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.Concept-Title-Dark,
.Concept-Title-Dark {
  color: black;
  font-size: 70px;
  line-height: 70px;
  font-weight: 700;
  font-style: normal;
  font-family: "Roboto Condensed";
  padding: 0px 0px 10px 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px;
  letter-spacing: 5px; }

.tp-caption.Concept-MoreBtn-Dark,
.Concept-MoreBtn-Dark {
  color: black;
  font-size: 30px;
  line-height: 30px;
  font-weight: 300;
  font-style: normal;
  font-family: Roboto;
  padding: 10px 8px 7px 10px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border-width: 0px;
  border-radius: 50px 50px 50px 50px;
  letter-spacing: 1px;
  text-align: left; }

.tp-caption.Concept-MoreBtn-Dark:hover,
.Concept-MoreBtn-Dark:hover {
  color: white;
  text-decoration: none;
  background-color: black;
  border-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border-width: 0px;
  border-radius: 50px 50px 50px 50px; }

.tp-caption.Concept-Content-Dark,
.Concept-Content-Dark {
  color: black;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto Condensed";
  padding: 0px 0px 0px 0px;
  text-decoration: none;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border-color: white;
  border-style: none;
  border-width: 2px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.Concept-Notice,
.Concept-Notice {
  color: white;
  font-size: 15px;
  line-height: 15px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto Condensed";
  padding: 0px 0px 0px 0px;
  text-decoration: none;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border-color: white;
  border-style: none;
  border-width: 2px;
  border-radius: 0px 0px 0px 0px;
  letter-spacing: 2px; }

.tp-caption.Concept-Content a,
.tp-caption.Concept-Content a:visited {
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
  font-weight: 700 !important; }

.tp-caption.Concept-Content a:hover {
  border-bottom: 1px solid transparent !important; }

.tp-caption.Concept-Content-Dark a,
.tp-caption.Concept-Content-Dark a:visited {
  color: #000 !important;
  border-bottom: 1px solid #000 !important;
  font-weight: 700 !important; }

.tp-caption.Concept-Content-Dark a:hover {
  border-bottom: 1px solid transparent !important; }

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
  color: #0084B4 !important; }

.tp-caption.Twitter-Content a:hover {
  color: #0084B4 !important;
  text-decoration: underline !important; }

.tp-caption.Creative-Title,
.Creative-Title {
  color: white;
  font-size: 70px;
  line-height: 70px;
  font-weight: 400;
  font-style: normal;
  font-family: "Playfair Display";
  padding: 0px 0px 0px 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.Creative-SubTitle,
.Creative-SubTitle {
  color: white;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  font-style: normal;
  font-family: Lato;
  padding: 0px 0px 0px 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px;
  letter-spacing: 2px; }

.tp-caption.Creative-Button,
.Creative-Button {
  color: #f53700;
  font-size: 13px;
  line-height: 13px;
  font-weight: 400;
  font-style: normal;
  font-family: Lato;
  padding: 15px 50px 15px 50px;
  text-decoration: none;
  text-align: left;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(245, 55, 0, 0.25);
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 0px 0px;
  letter-spacing: 2px; }

.tp-caption.Creative-Button:hover,
.Creative-Button:hover {
  color: #f53700;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: #f53700;
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.subcaption,
.subcaption {
  color: #6f7c82;
  font-size: 19px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  font-family: roboto;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: black;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-shadow: none;
  text-align: left; }

.tp-caption.RedDot,
.RedDot {
  color: black;
  font-weight: 400;
  font-style: normal;
  padding: 0px 0px 0px 0px;
  text-decoration: none;
  text-align: left;
  background-color: #d50000;
  border-color: white;
  border-style: solid;
  border-width: 5px;
  border-radius: 50px 50px 50px 50px; }

.tp-caption.RedDot:hover,
.RedDot:hover {
  color: black;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.75);
  border-color: #d50000;
  border-style: solid;
  border-width: 5px;
  border-radius: 50px 50px 50px 50px; }

.tp-caption.SlidingOverlays-Title,
.SlidingOverlays-Title {
  color: white;
  font-size: 50px;
  line-height: 50px;
  font-weight: 400;
  font-style: normal;
  font-family: "Playfair Display";
  padding: 0px 0px 0px 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.SlidingOverlays-Title,
.SlidingOverlays-Title {
  color: white;
  font-size: 50px;
  line-height: 50px;
  font-weight: 400;
  font-style: normal;
  font-family: "Playfair Display";
  padding: 0px 0px 0px 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.Woo-TitleLarge,
.Woo-TitleLarge {
  color: black;
  font-size: 40px;
  line-height: 40px;
  font-weight: 400;
  font-style: normal;
  font-family: "Playfair Display";
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.Woo-Rating,
.Woo-Rating {
  color: black;
  font-size: 14px;
  line-height: 30px;
  font-weight: 300;
  font-style: normal;
  font-family: Roboto;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.Woo-SubTitle,
.Woo-SubTitle {
  color: black;
  font-size: 18px;
  line-height: 18px;
  font-weight: 300;
  font-style: normal;
  font-family: Roboto;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center;
  letter-spacing: 2px; }

.tp-caption.Woo-PriceLarge,
.Woo-PriceLarge {
  color: black;
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
  font-style: normal;
  font-family: Roboto;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.Woo-ProductInfo,
.Woo-ProductInfo {
  color: black;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 12px 75px 12px 50px;
  text-decoration: none;
  background-color: #fecf72;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px 4px 4px 4px;
  text-align: left; }

.tp-caption.Woo-ProductInfo:hover,
.Woo-ProductInfo:hover {
  color: black;
  text-decoration: none;
  background-color: #f3a847;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px 4px 4px 4px; }

.tp-caption.Woo-AddToCart,
.Woo-AddToCart {
  color: black;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 12px 35px 12px 35px;
  text-decoration: none;
  background-color: #fecf72;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px 4px 4px 4px;
  text-align: left; }

.tp-caption.Woo-AddToCart:hover,
.Woo-AddToCart:hover {
  color: black;
  text-decoration: none;
  background-color: #f3a847;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px 4px 4px 4px; }

.tp-caption.Woo-TitleLarge,
.Woo-TitleLarge {
  color: black;
  font-size: 40px;
  line-height: 40px;
  font-weight: 400;
  font-style: normal;
  font-family: "Playfair Display";
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.Woo-SubTitle,
.Woo-SubTitle {
  color: black;
  font-size: 18px;
  line-height: 18px;
  font-weight: 300;
  font-style: normal;
  font-family: Roboto;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center;
  letter-spacing: 2px; }

.tp-caption.Woo-PriceLarge,
.Woo-PriceLarge {
  color: black;
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
  font-style: normal;
  font-family: Roboto;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.Woo-ProductInfo,
.Woo-ProductInfo {
  color: black;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 12px 75px 12px 50px;
  text-decoration: none;
  background-color: #fecf72;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px 4px 4px 4px;
  text-align: left; }

.tp-caption.Woo-ProductInfo:hover,
.Woo-ProductInfo:hover {
  color: black;
  text-decoration: none;
  background-color: #f3a847;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px 4px 4px 4px; }

.tp-caption.Woo-AddToCart,
.Woo-AddToCart {
  color: black;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 12px 35px 12px 35px;
  text-decoration: none;
  background-color: #fecf72;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px 4px 4px 4px;
  text-align: left; }

.tp-caption.Woo-AddToCart:hover,
.Woo-AddToCart:hover {
  color: black;
  text-decoration: none;
  background-color: #f3a847;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px 4px 4px 4px; }

.tp-caption.FullScreen-Toggle,
.FullScreen-Toggle {
  color: white;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  font-family: Raleway;
  padding: 11px 8px 11px 12px;
  text-decoration: none;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.5);
  border-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px;
  letter-spacing: 3px;
  text-align: left; }

.tp-caption.FullScreen-Toggle:hover,
.FullScreen-Toggle:hover {
  color: white;
  text-decoration: none;
  background-color: black;
  border-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.Agency-Title,
.Agency-Title {
  color: white;
  font-size: 70px;
  line-height: 70px;
  font-weight: 900;
  font-style: normal;
  font-family: lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left;
  letter-spacing: 10px; }

.tp-caption.Agency-SubTitle,
.Agency-SubTitle {
  color: white;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  font-style: italic;
  font-family: Georgia, serif;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.Agency-PlayBtn,
.Agency-PlayBtn {
  color: white;
  font-size: 30px;
  line-height: 71px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 0px 0px 0px 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 2px;
  border-radius: 100px 100px 100px 100px;
  text-align: center; }

.tp-caption.Agency-PlayBtn:hover,
.Agency-PlayBtn:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 2px;
  border-radius: 100px 100px 100px 100px;
  cursor: pointer; }

.tp-caption.Agency-SmallText,
.Agency-SmallText {
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 900;
  font-style: normal;
  font-family: lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left;
  letter-spacing: 5px; }

.tp-caption.Agency-Social,
.Agency-Social {
  color: #333333;
  font-size: 25px;
  line-height: 50px;
  font-weight: 400;
  font-style: normal;
  font-family: Georgia, serif;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: #333333;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px 30px 30px 30px;
  text-align: center; }

.tp-caption.Agency-Social:hover,
.Agency-Social:hover {
  color: white;
  text-decoration: none;
  background-color: #333333;
  border-color: #333333;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px 30px 30px 30px;
  cursor: pointer; }

.tp-caption.Agency-CloseBtn,
.Agency-CloseBtn {
  color: white;
  font-size: 50px;
  line-height: 50px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 0px 0px 0px 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0);
  border-style: none;
  border-width: 0px;
  border-radius: 100px 100px 100px 100px;
  text-align: center; }

.tp-caption.Agency-CloseBtn:hover,
.Agency-CloseBtn:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0);
  border-style: none;
  border-width: 0px;
  border-radius: 100px 100px 100px 100px;
  cursor: pointer; }

.tp-caption.Dining-Title,
.Dining-Title {
  color: white;
  font-size: 70px;
  line-height: 70px;
  font-weight: 400;
  font-style: normal;
  font-family: Georgia, serif;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left;
  letter-spacing: 10px; }

.tp-caption.Dining-SubTitle,
.Dining-SubTitle {
  color: white;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  font-family: Georgia, serif;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.Dining-BtnLight,
.Dining-BtnLight {
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
  font-style: normal;
  font-family: Lato;
  padding: 17px 73px 17px 50px;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.25);
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 0px 0px;
  text-align: left;
  letter-spacing: 2px; }

.tp-caption.Dining-BtnLight:hover,
.Dining-BtnLight:hover {
  color: white;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 0px 0px; }

.tp-caption.Dining-Social,
.Dining-Social {
  color: white;
  font-size: 25px;
  line-height: 50px;
  font-weight: 400;
  font-style: normal;
  font-family: Georgia, serif;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.25);
  border-style: solid;
  border-width: 1px;
  border-radius: 30px 30px 30px 30px;
  text-align: center; }

.tp-caption.Dining-Social:hover,
.Dining-Social:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 30px 30px 30px 30px;
  cursor: pointer; }

tp-caption.Team-Thumb,
.Team-Thumb {
  color: white;
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  font-style: normal;
  font-family: Arial;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.Team-Thumb:hover,
.Team-Thumb:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  cursor: pointer; }

.tp-caption.Team-Name,
.Team-Name {
  color: white;
  font-size: 70px;
  line-height: 70px;
  font-weight: 900;
  font-style: normal;
  font-family: Roboto;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.Team-Position,
.Team-Position {
  color: white;
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: Georgia, serif;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.Team-Description,
.Team-Description {
  color: white;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  font-style: normal;
  font-family: Roboto;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.Team-Social,
.Team-Social {
  color: white;
  font-size: 50px;
  line-height: 50px;
  font-weight: 400;
  font-style: normal;
  font-family: Arial;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.Team-Social:hover,
.Team-Social:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer; }

.tp-caption.VideoControls-Play,
.VideoControls-Play {
  color: black;
  font-size: 50px;
  line-height: 120px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 0px 0px 0px 7px;
  text-decoration: none;
  background-color: white;
  border-color: black;
  border-style: solid;
  border-width: 0px;
  border-radius: 100px 100px 100px 100px;
  text-align: center; }

.tp-caption.VideoControls-Play:hover,
.VideoControls-Play:hover {
  color: black;
  text-decoration: none;
  background-color: white;
  border-color: black;
  border-style: solid;
  border-width: 0px;
  border-radius: 100px 100px 100px 100px;
  cursor: pointer; }

.tp-caption.VideoPlayer-Title,
.VideoPlayer-Title {
  color: white;
  font-size: 40px;
  line-height: 40px;
  font-weight: 900;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left;
  letter-spacing: 10px; }

.tp-caption.VideoPlayer-SubTitle,
.VideoPlayer-SubTitle {
  color: white;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  font-style: italic;
  font-family: Georgia, serif;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.VideoPlayer-Social,
.VideoPlayer-Social {
  color: white;
  font-size: 50px;
  line-height: 50px;
  font-weight: 400;
  font-style: normal;
  font-family: Arial;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.VideoPlayer-Social:hover,
.VideoPlayer-Social:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer; }

.tp-caption.VideoControls-Mute,
.VideoControls-Mute {
  color: black;
  font-size: 20px;
  line-height: 50px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 0px 0px 0px 0px;
  text-decoration: none;
  background-color: white;
  border-color: black;
  border-style: solid;
  border-width: 0px;
  border-radius: 100px 100px 100px 100px;
  text-align: center; }

.tp-caption.VideoControls-Mute:hover,
.VideoControls-Mute:hover {
  color: black;
  text-decoration: none;
  background-color: white;
  border-color: black;
  border-style: solid;
  border-width: 0px;
  border-radius: 100px 100px 100px 100px;
  cursor: pointer; }

.tp-caption.VideoControls-Pause,
.VideoControls-Pause {
  color: black;
  font-size: 20px;
  line-height: 50px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 0px 0px 0px 0px;
  text-decoration: none;
  background-color: white;
  border-color: black;
  border-style: solid;
  border-width: 0px;
  border-radius: 100px 100px 100px 100px;
  text-align: center; }

.tp-caption.VideoControls-Pause:hover,
.VideoControls-Pause:hover {
  color: black;
  text-decoration: none;
  background-color: white;
  border-color: black;
  border-style: solid;
  border-width: 0px;
  border-radius: 100px 100px 100px 100px;
  cursor: pointer; }

.soundcloudwrapper iframe {
  width: 100% !important; }

.tp-caption.SleekLanding-Title,
.SleekLanding-Title {
  color: white;
  font-size: 35px;
  line-height: 40px;
  font-weight: 400;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left;
  letter-spacing: 5px; }

.tp-caption.SleekLanding-ButtonBG,
.SleekLanding-ButtonBG {
  color: black;
  font-weight: 700;
  font-style: normal;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 0px;
  border-radius: 5px 5px 5px 5px;
  text-align: left;
  box-shadow: inset 0px 2px 0px 0px rgba(0, 0, 0, 0.15); }

.tp-caption.SleekLanding-SmallTitle,
.SleekLanding-SmallTitle {
  color: white;
  font-size: 13px;
  line-height: 50px;
  font-weight: 900;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left;
  letter-spacing: 2px; }

.tp-caption.SleekLanding-BottomText,
.SleekLanding-BottomText {
  color: white;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.SleekLanding-Social,
.SleekLanding-Social {
  color: white;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: Arial;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.SleekLanding-Social:hover,
.SleekLanding-Social:hover {
  color: rgba(0, 0, 0, 0.25);
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  cursor: pointer; }

#rev_slider_429_1_wrapper .tp-loader.spinner2 {
  background-color: #555555 !important; }

.tp-fat {
  font-weight: 900 !important; }

.tp-caption.PostSlider-Category,
.PostSlider-Category {
  color: black;
  font-size: 15px;
  line-height: 15px;
  font-weight: 300;
  font-style: normal;
  font-family: Roboto;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  letter-spacing: 3px;
  text-align: left; }

.tp-caption.PostSlider-Title,
.PostSlider-Title {
  color: black;
  font-size: 40px;
  line-height: 40px;
  font-weight: 400;
  font-style: normal;
  font-family: "Playfair Display";
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.PostSlider-Content,
.PostSlider-Content {
  color: #777777;
  font-size: 15px;
  line-height: 23px;
  font-weight: 400;
  font-style: normal;
  font-family: Roboto;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.PostSlider-Button,
.PostSlider-Button {
  color: black;
  font-size: 15px;
  line-height: 40px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 1px 56px 1px 32px;
  text-decoration: none;
  background-color: white;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 0px 0px;
  text-align: left; }

.tp-caption.PostSlider-Button:hover,
.PostSlider-Button:hover {
  color: black;
  text-decoration: none;
  background-color: #eeeeee;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer; }

/* media queries */
.tp-caption.LandingPage-Title,
.LandingPage-Title {
  color: white;
  font-size: 70px;
  line-height: 80px;
  font-weight: 900;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left;
  letter-spacing: 10px; }

.tp-caption.LandingPage-SubTitle,
.LandingPage-SubTitle {
  color: white;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  font-style: italic;
  font-family: Georgia, serif;
  padding: 0 0 0 0px;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.LandingPage-Button,
.LandingPage-Button {
  color: black;
  font-size: 15px;
  line-height: 54px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 0px 35px 0px 35px;
  text-decoration: none;
  background-color: white;
  border-color: black;
  border-style: solid;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px;
  text-align: left;
  letter-spacing: 3px; }

.tp-caption.LandingPage-Button:hover,
.LandingPage-Button:hover {
  color: black;
  text-decoration: none;
  background-color: white;
  border-color: black;
  border-style: solid;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer; }

.tp-caption.App-Content a,
.tp-caption.App-Content a:visited {
  color: #89124e !important;
  border-bottom: 1px solid transparent !important;
  font-weight: bold !important; }

.tp-caption.App-Content a:hover {
  border-bottom: 1px solid #89124e !important; }

.tp-caption.RockBand-LogoText,
.RockBand-LogoText {
  color: white;
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
  font-style: normal;
  font-family: Oswald;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
  color: #fff !important;
  text-decoration: underline !important; }

.tp-caption.Twitter-Content a:hover {
  color: #fff !important;
  text-decoration: none !important; }

.soundcloudwrapper iframe {
  width: 100% !important; }

.tp-caption.Agency-LogoText,
.Agency-LogoText {
  color: white;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center;
  letter-spacing: 1px; }

.tp-caption.ComingSoon-Highlight,
.ComingSoon-Highlight {
  color: white;
  font-size: 20px;
  line-height: 37px;
  font-weight: 400;
  font-style: normal;
  font-family: Lato;
  padding: 0 20px 3px 20px;
  text-decoration: none;
  text-align: left;
  background-color: #009aee;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.ComingSoon-Count,
.ComingSoon-Count {
  color: white;
  font-size: 50px;
  line-height: 50px;
  font-weight: 900;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.ComingSoon-CountUnit,
.ComingSoon-CountUnit {
  color: white;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.ComingSoon-NotifyMe,
.ComingSoon-NotifyMe {
  color: #a49d8f;
  font-size: 27px;
  line-height: 35px;
  font-weight: 600;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

#mc_embed_signup input#mce-EMAIL {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  color: #000;
  background-color: #fff;
  line-height: 46px;
  padding: 0 20px;
  cursor: text;
  border: 1px solid #fff;
  width: 400px;
  margin-bottom: 0px;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  transition: background-color 0.5s;
  border-radius: 0px; }

#mc_embed_signup input#mce-EMAIL[type="email"]:focus {
  background-color: #fff;
  border: 1px solid #666;
  border-right: 0; }

#mc_embed_signup input#mc-embedded-subscribe,
#mc_embed_signup input#mc-embedded-subscribe:focus {
  font-family: "Lato", sans-serif;
  line-height: 46px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 900;
  padding: 0 20px;
  border: 1px solid #009aee;
  background: #009aee;
  color: #fff;
  border-radius: 0px; }

#mc_embed_signup input#mc-embedded-subscribe:hover {
  background: #0083d4; }

@media only screen and (max-width: 767px) {
  #mc_embed_signup input#mce-EMAIL {
    width: 200px; } }

.tp-caption.Agency-SmallTitle,
.Agency-SmallTitle {
  color: white;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  font-style: normal;
  font-family: lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center;
  letter-spacing: 6px; }

.tp-caption.Agency-SmallContent,
.Agency-SmallContent {
  color: white;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  font-family: lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.Agency-SmallLink,
.Agency-SmallLink {
  color: #f87c09;
  font-size: 12px;
  line-height: 22px;
  font-weight: 700;
  font-style: normal;
  font-family: lato;
  padding: 0 0 0px 0;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center;
  letter-spacing: 2px;
  border-bottom: 1px solid #f87c09 !important; }

.tp-caption.Agency-SmallLink:hover,
.Agency-SmallLink:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  cursor: pointer; }

.tp-caption.Agency-NavButton,
.Agency-NavButton {
  color: #333333;
  font-size: 17px;
  line-height: 50px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 0px 0px 0px 0px;
  text-decoration: none;
  text-align: center;
  background-color: white;
  border-color: black;
  border-style: solid;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px;
  text-align: center; }

.tp-caption.Agency-NavButton:hover,
.Agency-NavButton:hover {
  color: white;
  text-decoration: none;
  background-color: #333333;
  border-color: black;
  border-style: solid;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer; }

.tp-caption.Agency-SmallLinkGreen,
.Agency-SmallLinkGreen {
  color: #6db19b;
  font-size: 12px;
  line-height: 22px;
  font-weight: 700;
  font-style: normal;
  font-family: lato;
  padding: 0 0 0px 0;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center;
  letter-spacing: 2px;
  border-bottom: 1px solid #6db19b !important; }

.tp-caption.Agency-SmallLinkGreen:hover,
.Agency-SmallLinkGreen:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  cursor: pointer; }

.tp-caption.Agency-SmallLinkBlue,
.Agency-SmallLinkBlue {
  color: #999999;
  font-size: 12px;
  line-height: 22px;
  font-weight: 700;
  font-style: normal;
  font-family: lato;
  padding: 0 0 0px 0;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center;
  letter-spacing: 2px;
  border-bottom: 1px solid #999 !important; }

.tp-caption.Agency-SmallLinkBlue:hover,
.Agency-SmallLinkBlue:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  cursor: pointer; }

.tp-caption.Agency-LogoText,
.Agency-LogoText {
  color: white;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center;
  letter-spacing: 1px; }

.tp-caption.Agency-ArrowTooltip,
.Agency-ArrowTooltip {
  color: #333333;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  font-family: "Permanent Marker";
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.Agency-SmallSocial,
.Agency-SmallSocial {
  color: white;
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: Arial;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.Agency-SmallSocial:hover,
.Agency-SmallSocial:hover {
  color: #333333;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer; }

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
  color: #0084B4 !important; }

.tp-caption.Twitter-Content a:hover {
  color: #0084B4 !important;
  text-decoration: underline !important; }

.tp-caption.CreativeFrontPage-Btn,
.CreativeFrontPage-Btn {
  color: white;
  font-size: 14px;
  line-height: 60px;
  font-weight: 900;
  font-style: normal;
  font-family: Roboto;
  padding: 0px 50px 0px 50px;
  text-decoration: none;
  text-align: left;
  background-color: #00685c;
  border-color: black;
  border-style: solid;
  border-width: 0px;
  border-radius: 4px 4px 4px 4px;
  letter-spacing: 2px; }

.tp-caption.CreativeFrontPage-Btn:hover,
.CreativeFrontPage-Btn:hover {
  color: white;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.25);
  border-color: black;
  border-style: solid;
  border-width: 0px;
  border-radius: 4px 4px 4px 4px;
  cursor: pointer; }

.tp-caption.CreativeFrontPage-Menu,
.CreativeFrontPage-Menu {
  color: white;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  font-style: normal;
  font-family: roboto;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  letter-spacing: 2px; }

.tp-flip-index {
  z-index: 1000 !important; }

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
  color: #0084B4 !important; }

.tp-caption.Twitter-Content a:hover {
  color: #0084B4 !important;
  text-decoration: underline !important; }

.tp-caption.FullScreenMenu-Category,
.FullScreenMenu-Category {
  color: #111111;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
  font-family: BenchNine;
  padding: 21px 30px 16px 30px;
  text-decoration: none;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.9);
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  letter-spacing: 3px; }

.tp-caption.FullScreenMenu-Title,
.FullScreenMenu-Title {
  color: white;
  font-size: 65px;
  line-height: 70px;
  font-weight: 700;
  font-style: normal;
  font-family: BenchNine;
  padding: 21px 30px 16px 30px;
  text-decoration: none;
  text-align: left;
  background-color: rgba(17, 17, 17, 0.9);
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px; }

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
  color: #0084B4 !important; }

.tp-caption.Twitter-Content a:hover {
  color: #0084B4 !important;
  text-decoration: underline !important; }

.tp-caption.TechJournal-Button,
.TechJournal-Button {
  color: white;
  font-size: 13px;
  line-height: 40px;
  font-weight: 900;
  font-style: normal;
  font-family: Raleway;
  padding: 1px 30px 1px 30px;
  text-decoration: none;
  text-align: left;
  background-color: #8a00ff;
  border-color: black;
  border-style: solid;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px;
  letter-spacing: 3px; }

.tp-caption.TechJournal-Button:hover,
.TechJournal-Button:hover {
  color: black;
  text-decoration: none;
  background-color: white;
  border-color: black;
  border-style: solid;
  border-width: 0px;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer; }

.tp-caption.TechJournal-Big,
.TechJournal-Big {
  color: white;
  font-size: 120px;
  line-height: 120px;
  font-weight: 900;
  font-style: normal;
  font-family: Raleway;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  letter-spacing: 0px; }

.rev_slider {
  overflow: hidden; }

.effect_layer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%; }

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
  color: #0084B4 !important; }

.tp-caption.Twitter-Content a:hover {
  color: #0084B4 !important;
  text-decoration: underline !important; }

#menu_forcefullwidth {
  z-index: 5000;
  position: fixed !important;
  top: 0px;
  left: 0px;
  width: 100%; }

.tp-caption.FullSiteBlock-Title,
.FullSiteBlock-Title {
  color: #333333;
  font-size: 55px;
  line-height: 65px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-SubTitle,
.FullSiteBlock-SubTitle {
  color: #333333;
  font-size: 25px;
  line-height: 34px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-Link,
.FullSiteBlock-Link {
  color: #0096ff;
  font-size: 25px;
  line-height: 24px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-Link:hover,
.FullSiteBlock-Link:hover {
  color: #333333;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  cursor: pointer; }

.tp-caption.FullSiteBlock-DownButton,
.FullSiteBlock-DownButton {
  color: #333333;
  font-size: 25px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 1px 1px 1px 1px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: #333333;
  border-style: solid;
  border-width: 1px;
  border-radius: 30px 30px 30px 30px;
  text-align: center; }

.tp-caption.FullSiteBlock-DownButton:hover,
.FullSiteBlock-DownButton:hover {
  color: #0096ff;
  text-decoration: none;
  background-color: transparent;
  border-color: #0096ff;
  border-style: solid;
  border-width: 1px;
  border-radius: 30px 30px 30px 30px;
  cursor: pointer; }

.tp-caption.FullSiteBlock-Title,
.FullSiteBlock-Title {
  color: #333333;
  font-size: 55px;
  line-height: 65px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-SubTitle,
.FullSiteBlock-SubTitle {
  color: #333333;
  font-size: 25px;
  line-height: 34px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-Link,
.FullSiteBlock-Link {
  color: #0096ff;
  font-size: 25px;
  line-height: 24px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-Link:hover,
.FullSiteBlock-Link:hover {
  color: #333333;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  cursor: pointer; }

.tp-caption.FullSiteBlock-DownButton,
.FullSiteBlock-DownButton {
  color: #333333;
  font-size: 25px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 1px 1px 1px 1px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: #333333;
  border-style: solid;
  border-width: 1px;
  border-radius: 30px 30px 30px 30px;
  text-align: center; }

.tp-caption.FullSiteBlock-DownButton:hover,
.FullSiteBlock-DownButton:hover {
  color: #0096ff;
  text-decoration: none;
  background-color: transparent;
  border-color: #0096ff;
  border-style: solid;
  border-width: 1px;
  border-radius: 30px 30px 30px 30px;
  cursor: pointer; }

.tp-caption.FullSiteBlock-Title,
.FullSiteBlock-Title {
  color: #333333;
  font-size: 55px;
  line-height: 65px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-SubTitle,
.FullSiteBlock-SubTitle {
  color: #333333;
  font-size: 25px;
  line-height: 34px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-DownButton,
.FullSiteBlock-DownButton {
  color: #333333;
  font-size: 25px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 1px 1px 1px 1px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: #333333;
  border-style: solid;
  border-width: 1px;
  border-radius: 30px 30px 30px 30px;
  text-align: center; }

.tp-caption.FullSiteBlock-DownButton:hover,
.FullSiteBlock-DownButton:hover {
  color: #0096ff;
  text-decoration: none;
  background-color: transparent;
  border-color: #0096ff;
  border-style: solid;
  border-width: 1px;
  border-radius: 30px 30px 30px 30px;
  cursor: pointer; }

.tp-caption.FullSiteBlock-Title,
.FullSiteBlock-Title {
  color: #333333;
  font-size: 55px;
  line-height: 65px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-SubTitle,
.FullSiteBlock-SubTitle {
  color: #333333;
  font-size: 25px;
  line-height: 34px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-Link,
.FullSiteBlock-Link {
  color: #0096ff;
  font-size: 25px;
  line-height: 24px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-Link:hover,
.FullSiteBlock-Link:hover {
  color: #333333;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  cursor: pointer; }

.tp-caption.FullSiteBlock-DownButton,
.FullSiteBlock-DownButton {
  color: #333333;
  font-size: 25px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  padding: 1px 1px 1px 1px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: #333333;
  border-style: solid;
  border-width: 1px;
  border-radius: 30px 30px 30px 30px;
  text-align: center; }

.tp-caption.FullSiteBlock-DownButton:hover,
.FullSiteBlock-DownButton:hover {
  color: #0096ff;
  text-decoration: none;
  background-color: transparent;
  border-color: #0096ff;
  border-style: solid;
  border-width: 1px;
  border-radius: 30px 30px 30px 30px;
  cursor: pointer; }

.rev_slider {
  overflow: hidden; }

.effect_layer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%; }

.gyges .tp-thumb {
  opacity: 1; }

.gyges .tp-thumb-img-wrap {
  padding: 3px;
  background-color: rgba(0, 0, 0, 0.25);
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0px;
  box-sizing: border-box;
  transition: all 0.3s;
  -webkit-transition: all 0.3s; }

.gyges .tp-thumb-image {
  padding: 3px;
  display: block;
  box-sizing: border-box;
  position: relative;
  -webkit-box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25); }

.gyges .tp-thumb:hover .tp-thumb-img-wrap,
.gyges .tp-thumb.selected .tp-thumb-img-wrap {
  background: -moz-linear-gradient(top, white 0%, white 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, white), color-stop(100%, white));
  background: -webkit-linear-gradient(top, white 0%, white 100%);
  background: -o-linear-gradient(top, white 0%, white 100%);
  background: -ms-linear-gradient(top, white 0%, white 100%);
  background: linear-gradient(to bottom, white 0%, white 100%); }

.tp-caption.FullSiteBlock-Title,
.FullSiteBlock-Title {
  color: #333333;
  font-size: 55px;
  line-height: 65px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-SubTitle,
.FullSiteBlock-SubTitle {
  color: #333333;
  font-size: 25px;
  line-height: 34px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-Link,
.FullSiteBlock-Link {
  color: #0096ff;
  font-size: 25px;
  line-height: 24px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-Link:hover,
.FullSiteBlock-Link:hover {
  color: #333333;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  cursor: pointer; }

.tp-caption.FullSiteBlock-SubTitle,
.FullSiteBlock-SubTitle {
  color: #333333;
  font-size: 25px;
  line-height: 34px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-SubTitle,
.FullSiteBlock-SubTitle {
  color: #333333;
  font-size: 25px;
  line-height: 34px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.FullSiteBlock-FooterLink,
.FullSiteBlock-FooterLink {
  color: #555555;
  font-size: 15px;
  line-height: 20px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: left; }

.tp-caption.FullSiteBlock-FooterLink:hover,
.FullSiteBlock-FooterLink:hover {
  color: #0096ff;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  cursor: pointer; }

.fb-share-button.fb_iframe_widget iframe {
  width: 115px !important; }

#tp-socialwrapper {
  opacity: 0; }

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
  color: #0084B4 !important; }

.tp-caption.Twitter-Content a:hover {
  color: #0084B4 !important;
  text-decoration: underline !important; }

#menu_forcefullwidth {
  z-index: 5000;
  position: fixed !important;
  top: 0px;
  left: 0px;
  width: 100%; }

#tp-menubg {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }

#mc_embed_signup input[type="email"] {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  background-color: #fff;
  color: #888 !important;
  line-height: 46px;
  padding: 0 20px;
  cursor: text;
  border: 0;
  width: 400px;
  margin-bottom: 0px;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  transition: background-color 0.5s;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

#mc_embed_signup input[type="email"]::-webkit-input-placeholder {
  color: #888 !important; }

#mc_embed_signup input[type="email"]::-moz-placeholder {
  color: #888 !important; }

#mc_embed_signup input[type="email"]:-ms-input-placeholder {
  color: #888 !important; }

#mc_embed_signup input[type="email"]:focus {
  background-color: #f5f5f5;
  color: #454545; }

#mc_embed_signup input#mc-embedded-subscribe,
#mc_embed_signup input#mc-embedded-subscribe:focus {
  font-family: "Lato", sans-serif;
  line-height: 46px;
  font-size: 16px;
  font-weight: 700;
  padding: 0 30px;
  border: 0;
  background: #f04531;
  text-transform: none;
  color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

#mc_embed_signup input#mc-embedded-subscribe:hover {
  background: #e03727; }

@media only screen and (max-width: 767px) {
  #mc_embed_signup input[type="email"] {
    width: 260px; } }

@media only screen and (max-width: 480px) {
  #mc_embed_signup input[type="email"] {
    width: 160px; } }

#rev_slider_167_6 .uranus.tparrows {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0); }

#rev_slider_167_6 .uranus.tparrows:before {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 40px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s; }

#rev_slider_167_6 .uranus.tparrows:hover:before {
  opacity: 0.75; }

.tp-caption.FullSiteBlock-SubTitle,
.FullSiteBlock-SubTitle {
  color: #333333;
  font-size: 25px;
  line-height: 34px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

.tp-caption.ParallaxWebsite-FooterItem,
.ParallaxWebsite-FooterItem {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px; }

.tp-caption.ParallaxWebsite-FooterItem:hover,
.ParallaxWebsite-FooterItem:hover {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  cursor: pointer; }

.fb-share-button.fb_iframe_widget iframe {
  width: 115px !important; }

iframe.twitter-share-button {
  display: none; }

.fb-share-button.fb_iframe_widget iframe {
  display: none; }

.tp-caption.FullSiteBlock-Link,
.FullSiteBlock-Link {
  color: #0096ff;
  font-size: 25px;
  line-height: 24px;
  font-weight: 300;
  font-style: normal;
  font-family: Lato;
  padding: 0 0 0 0px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  border-width: 0px;
  border-radius: 0 0 0 0px;
  text-align: center; }

/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 ARES SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
.ares.tparrows {
  cursor: pointer;
  background: #fff;
  min-width: 60px;
  min-height: 60px;
  position: absolute;
  display: block;
  z-index: 100;
  border-radius: 50%; }

.ares.tparrows:before {
  font-family: "revicons";
  font-size: 25px;
  color: #aaa;
  display: block;
  line-height: 60px;
  text-align: center;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
  z-index: 2;
  position: relative; }

.ares.tparrows.tp-leftarrow:before {
  content: "\e81f"; }

.ares.tparrows.tp-rightarrow:before {
  content: "\e81e"; }

.ares.tparrows:hover:before {
  color: #000; }

.ares .tp-title-wrap {
  position: absolute;
  z-index: 1;
  display: inline-block;
  background: #fff;
  min-height: 60px;
  line-height: 60px;
  top: 0px;
  margin-left: 30px;
  border-radius: 0px 30px 30px 0px;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  transform-origin: 0% 50%;
  -webkit-transform-origin: 0% 50%; }

.ares.tp-rightarrow .tp-title-wrap {
  right: 0px;
  margin-right: 30px;
  margin-left: 0px;
  -webkit-transform-origin: 100% 50%;
  border-radius: 30px 0px 0px 30px; }

.ares.tparrows:hover .tp-title-wrap {
  transform: scaleX(1) scaleY(1);
  -webkit-transform: scaleX(1) scaleY(1); }

.ares .tp-arr-titleholder {
  position: relative;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transform: translateX(200px);
  text-transform: uppercase;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 60px;
  white-space: nowrap;
  padding: 0px 20px;
  margin-left: 10px;
  opacity: 0; }

.ares.tp-rightarrow .tp-arr-titleholder {
  transform: translateX(-200px);
  margin-left: 0px;
  margin-right: 10px; }

.ares.tparrows:hover .tp-arr-titleholder {
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
  transition-delay: 0.1s;
  opacity: 1; }

/* BULLETS */
.ares.tp-bullets:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
  box-sizing: content-box; }

.ares .tp-bullet {
  width: 13px;
  height: 13px;
  position: absolute;
  background: #e5e5e5;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: content-box; }

.ares .tp-bullet:hover,
.ares .tp-bullet.selected {
  background: #fff; }

.ares .tp-bullet-title {
  position: absolute;
  color: #888;
  font-size: 12px;
  padding: 0px 10px;
  font-weight: 600;
  right: 27px;
  top: -4px;
  background: #fff;
  background: rgba(255, 255, 255, 0.75);
  visibility: hidden;
  transform: translateX(-20px);
  -webkit-transform: translateX(-20px);
  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  line-height: 20px;
  white-space: nowrap; }

.ares .tp-bullet-title:after {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.75);
  content: " ";
  position: absolute;
  right: -10px;
  top: 0px; }

.ares .tp-bullet:hover .tp-bullet-title {
  visibility: visible;
  transform: translateX(0px);
  -webkit-transform: translateX(0px); }

.ares .tp-bullet.selected:hover .tp-bullet-title {
  background: #fff; }

.ares .tp-bullet.selected:hover .tp-bullet-title:after {
  border-color: transparent transparent transparent #fff; }

.ares.tp-bullets:hover .tp-bullet-title {
  visibility: hidden; }

.ares.tp-bullets:hover .tp-bullet:hover .tp-bullet-title {
  visibility: visible; }

/* TABS */
.ares .tp-tab {
  opacity: 1;
  padding: 10px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  border-bottom: 1px solid #e5e5e5; }

.ares .tp-tab-image {
  width: 60px;
  height: 60px;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  display: inline-block;
  float: left; }

.ares .tp-tab-content {
  background: rgba(0, 0, 0, 0);
  position: relative;
  padding: 15px 15px 15px 85px;
  left: 0px;
  overflow: hidden;
  margin-top: -15px;
  box-sizing: border-box;
  color: #333;
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute; }

.ares .tp-tab-date {
  display: block;
  color: #aaa;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0px; }

.ares .tp-tab-title {
  display: block;
  text-align: left;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  line-height: 17px; }

.ares .tp-tab:hover,
.ares .tp-tab.selected {
  background: #eee; }

/* MEDIA QUERIES */
/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 CUSTOM SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
/* ARROWS */
.custom.tparrows {
  cursor: pointer;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  position: absolute;
  display: block;
  z-index: 10000; }

.custom.tparrows:hover {
  background: #000; }

.custom.tparrows:before {
  font-family: "revicons";
  font-size: 15px;
  color: #fff;
  display: block;
  line-height: 40px;
  text-align: center; }

.custom.tparrows.tp-leftarrow:before {
  content: "\e824"; }

.custom.tparrows.tp-rightarrow:before {
  content: "\e825"; }

/* BULLETS */
.custom.tp-bullets:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
  box-sizing: content-box; }

.custom .tp-bullet {
  width: 12px;
  height: 12px;
  position: absolute;
  background: #aaa;
  background: rgba(125, 125, 125, 0.5);
  cursor: pointer;
  box-sizing: content-box; }

.custom .tp-bullet:hover,
.custom .tp-bullet.selected {
  background: #7d7d7d; }

/* THUMBS */
/* TABS */
/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 DIONE SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
/* ARROWS */
.dione.tparrows {
  height: 100%;
  width: 100px;
  background: transparent;
  background: rgba(0, 0, 0, 0);
  line-height: 100%;
  transition: all 0.3s;
  -webkit-transition: all 0.3s; }

.dione.tparrows:hover {
  background: rgba(0, 0, 0, 0.45); }

.dione .tp-arr-imgwrapper {
  width: 100px;
  left: 0px;
  position: absolute;
  height: 100%;
  top: 0px;
  overflow: hidden; }

.dione.tp-rightarrow .tp-arr-imgwrapper {
  left: auto;
  right: 0px; }

.dione .tp-arr-imgholder {
  background-position: center center;
  background-size: cover;
  width: 100px;
  height: 100%;
  top: 0px;
  visibility: hidden;
  transform: translateX(-50px);
  -webkit-transform: translateX(-50px);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  opacity: 0;
  left: 0px; }

.dione.tparrows.tp-rightarrow .tp-arr-imgholder {
  right: 0px;
  left: auto;
  transform: translateX(50px);
  -webkit-transform: translateX(50px); }

.dione.tparrows:before {
  position: absolute;
  line-height: 30px;
  margin-left: -22px;
  top: 50%;
  left: 50%;
  font-size: 30px;
  margin-top: -15px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s; }

.dione.tparrows.tp-rightarrow:before {
  margin-left: 6px; }

.dione.tparrows:hover:before {
  transform: translateX(-20px);
  -webkit-transform: translateX(-20px);
  opacity: 0; }

.dione.tparrows.tp-rightarrow:hover:before {
  transform: translateX(20px);
  -webkit-transform: translateX(20px); }

.dione.tparrows:hover .tp-arr-imgholder {
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
  opacity: 1;
  visibility: visible; }

/* BULLETS */
.dione .tp-bullet {
  opacity: 1;
  width: 50px;
  height: 50px;
  padding: 3px;
  background: #000;
  background-color: rgba(0, 0, 0, 0.25);
  margin: 0px;
  box-sizing: border-box;
  transition: all 0.3s;
  -webkit-transition: all 0.3s; }

.dione .tp-bullet-image {
  display: block;
  box-sizing: border-box;
  position: relative;
  -webkit-box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  width: 44px;
  height: 44px;
  background-size: cover;
  background-position: center center; }

.dione .tp-bullet-title {
  position: absolute;
  bottom: 65px;
  display: inline-block;
  left: 50%;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 10px 30px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  opacity: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transform: translateZ(0.001px) translateX(-50%) translateY(14px);
  transform-origin: 50% 100%;
  -webkit-transform: translateZ(0.001px) translateX(-50%) translateY(14px);
  -webkit-transform-origin: 50% 100%;
  opacity: 0;
  white-space: nowrap; }

.dione .tp-bullet:hover .tp-bullet-title {
  transform: rotateX(0deg) translateX(-50%);
  -webkit-transform: rotateX(0deg) translateX(-50%);
  opacity: 1; }

.dione .tp-bullet.selected,
.dione .tp-bullet:hover {
  background: white;
  background: -moz-linear-gradient(top, white 0%, #777777 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, white), color-stop(100%, #777777));
  background: -webkit-linear-gradient(top, white 0%, #777777 100%);
  background: -o-linear-gradient(top, white 0%, #777777 100%);
  background: -ms-linear-gradient(top, white 0%, #777777 100%);
  background: linear-gradient(to bottom, white 0%, #777777 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#777777", GradientType=0 ); }

.dione .tp-bullet-title:after {
  content: " ";
  position: absolute;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
  bottom: -8px; }

/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 ERINYEN SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
/* ARROWS */
.erinyen.tparrows {
  cursor: pointer;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  min-width: 70px;
  min-height: 70px;
  position: absolute;
  display: block;
  z-index: 1000;
  border-radius: 35px; }

.erinyen.tparrows:before {
  font-family: "revicons";
  font-size: 20px;
  color: #fff;
  display: block;
  line-height: 70px;
  text-align: center;
  z-index: 2;
  position: relative; }

.erinyen.tparrows.tp-leftarrow:before {
  content: "\e824"; }

.erinyen.tparrows.tp-rightarrow:before {
  content: "\e825"; }

.erinyen .tp-title-wrap {
  position: absolute;
  z-index: 1;
  display: inline-block;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  min-height: 70px;
  line-height: 70px;
  top: 0px;
  margin-left: 0px;
  border-radius: 35px;
  overflow: hidden;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
  visibility: hidden;
  opacity: 0; }

.erinyen.tparrows:hover .tp-title-wrap {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible; }

.erinyen.tp-rightarrow .tp-title-wrap {
  right: 0px;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-transform-origin: 100% 50%;
  border-radius: 35px;
  padding-right: 20px;
  padding-left: 10px; }

.erinyen.tp-leftarrow .tp-title-wrap {
  padding-left: 20px;
  padding-right: 10px; }

.erinyen .tp-arr-titleholder {
  letter-spacing: 3px;
  position: relative;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transform: translateX(200px);
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  line-height: 70px;
  white-space: nowrap;
  padding: 0px 20px;
  margin-left: 11px;
  opacity: 0; }

.erinyen .tp-arr-imgholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center center;
  background-size: cover; }

.erinyen .tp-arr-img-over {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #000;
  background: rgba(0, 0, 0, 0.5); }

.erinyen.tp-rightarrow .tp-arr-titleholder {
  transform: translateX(-200px);
  margin-left: 0px;
  margin-right: 11px; }

.erinyen.tparrows:hover .tp-arr-titleholder {
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
  transition-delay: 0.1s;
  opacity: 1; }

/* BULLETS */
.erinyen.tp-bullets:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #555555;
  /* old browsers */
  background: -moz-linear-gradient(top, #555555 0%, #222222 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #555555), color-stop(100%, #222222));
  /* chrome,safari4+ */
  background: -webkit-linear-gradient(top, #555555 0%, #222222 100%);
  /* chrome10+,safari5.1+ */
  background: -o-linear-gradient(top, #555555 0%, #222222 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(top, #555555 0%, #222222 100%);
  /* ie10+ */
  background: linear-gradient(to bottom, #555555 0%, #222222 100%);
  /* w3c */
  filter: progid:dximagetransform.microsoft.gradient( startcolorstr="#555555", endcolorstr="#222222",gradienttype=0 );
  /* ie6-9 */
  padding: 10px 15px;
  margin-left: -15px;
  margin-top: -10px;
  box-sizing: content-box;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 1px rgba(33, 33, 33, 0.3); }

.erinyen .tp-bullet {
  width: 13px;
  height: 13px;
  position: absolute;
  background: #111;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: content-box; }

.erinyen .tp-bullet:hover,
.erinyen .tp-bullet.selected {
  background: #e5e5e5;
  /* old browsers */
  background: -moz-linear-gradient(top, #e5e5e5 0%, #999999 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(100%, #999999));
  /* chrome,safari4+ */
  background: -webkit-linear-gradient(top, #e5e5e5 0%, #999999 100%);
  /* chrome10+,safari5.1+ */
  background: -o-linear-gradient(top, #e5e5e5 0%, #999999 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(top, #e5e5e5 0%, #999999 100%);
  /* ie10+ */
  background: linear-gradient(to bottom, #e5e5e5 0%, #999999 100%);
  /* w3c */
  filter: progid:dximagetransform.microsoft.gradient( startcolorstr="#e5e5e5", endcolorstr="#999999",gradienttype=0 );
  /* ie6-9 */
  border: 1px solid #555;
  width: 12px;
  height: 12px; }

/* THUMBS */
.erinyen .tp-thumb {
  opacity: 1; }

.erinyen .tp-thumb-over {
  background: #000;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.erinyen .tp-thumb-more:before {
  font-family: "revicons";
  font-size: 12px;
  color: #aaa;
  color: rgba(255, 255, 255, 0.75);
  display: block;
  line-height: 12px;
  text-align: left;
  z-index: 2;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2; }

.erinyen .tp-thumb-more:before {
  content: "\e825"; }

.erinyen .tp-thumb-title {
  font-family: "Raleway";
  letter-spacing: 1px;
  font-size: 12px;
  color: #fff;
  display: block;
  line-height: 15px;
  text-align: left;
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  padding: 20px 35px 20px 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  font-weight: 500; }

.erinyen .tp-thumb.selected .tp-thumb-more:before,
.erinyen .tp-thumb:hover .tp-thumb-more:before {
  color: #aaa; }

.erinyen .tp-thumb.selected .tp-thumb-over,
.erinyen .tp-thumb:hover .tp-thumb-over {
  background: #fff; }

.erinyen .tp-thumb.selected .tp-thumb-title,
.erinyen .tp-thumb:hover .tp-thumb-title {
  color: #000; }

/* TABS */
.erinyen .tp-tab-title {
  color: #a8d8ee;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Roboto Slab";
  margin-bottom: 5px; }

.erinyen .tp-tab-desc {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  line-height: 25px;
  font-family: "Roboto Slab"; }

/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 GYGES SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
/* ARROWS */
/* BULLETS */
.gyges.tp-bullets:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #777777;
  /* Old browsers */
  background: -moz-linear-gradient(top, #777777 0%, #666666 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #777777), color-stop(100%, #666666));
  background: -webkit-linear-gradient(top, #777777 0%, #666666 100%);
  background: -o-linear-gradient(top, #777777 0%, #666666 100%);
  background: -ms-linear-gradient(top, #777777 0%, #666666 100%);
  background: linear-gradient(to bottom, #777777 0%, #666666 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#777777",  endColorstr="#666666",GradientType=0 );
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
  box-sizing: content-box;
  border-radius: 10px; }

.gyges .tp-bullet {
  width: 12px;
  height: 12px;
  position: absolute;
  background: #333;
  border: 3px solid #444;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: content-box; }

.gyges .tp-bullet:hover,
.gyges .tp-bullet.selected {
  background: #ffffff;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #e1e1e1 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #e1e1e1));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffffff 0%, #e1e1e1 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffffff 0%, #e1e1e1 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffffff 0%, #e1e1e1 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff",  endColorstr="#e1e1e1",GradientType=0 );
  /* IE6-9 */ }

/* THUMBS */
.gyges .tp-thumb {
  opacity: 1; }

.gyges .tp-thumb-img-wrap {
  padding: 3px;
  background: #000;
  background-color: rgba(0, 0, 0, 0.25);
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0px;
  box-sizing: border-box;
  transition: all 0.3s;
  -webkit-transition: all 0.3s; }

.gyges .tp-thumb-image {
  padding: 3px;
  display: block;
  box-sizing: border-box;
  position: relative;
  -webkit-box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25); }

.gyges .tp-thumb-title {
  position: absolute;
  bottom: 100%;
  display: inline-block;
  left: 50%;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px 30px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  margin-bottom: 20px;
  opacity: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transform: translateZ(0.001px) translateX(-50%) translateY(14px);
  transform-origin: 50% 100%;
  -webkit-transform: translateZ(0.001px) translateX(-50%) translateY(14px);
  -webkit-transform-origin: 50% 100%;
  white-space: nowrap; }

.gyges .tp-thumb:hover .tp-thumb-title {
  transform: rotateX(0deg) translateX(-50%);
  -webkit-transform: rotateX(0deg) translateX(-50%);
  opacity: 1; }

.gyges .tp-thumb:hover .tp-thumb-img-wrap,
.gyges .tp-thumb.selected .tp-thumb-img-wrap {
  background: white;
  background: -moz-linear-gradient(top, white 0%, #777777 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, white), color-stop(100%, #777777));
  background: -webkit-linear-gradient(top, white 0%, #777777 100%);
  background: -o-linear-gradient(top, white 0%, #777777 100%);
  background: -ms-linear-gradient(top, white 0%, #777777 100%);
  background: linear-gradient(to bottom, white 0%, #777777 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#777777", GradientType=0 ); }

.gyges .tp-thumb-title:after {
  content: " ";
  position: absolute;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: rgba(255, 255, 255, 0.8) transparent transparent transparent;
  bottom: -8px; }

/* TABS */
.gyges .tp-tab {
  opacity: 1;
  padding: 10px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15); }

.gyges .tp-tab-image {
  width: 60px;
  height: 60px;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  display: inline-block;
  float: left; }

.gyges .tp-tab-content {
  background: rgba(0, 0, 0, 0);
  position: relative;
  padding: 15px 15px 15px 85px;
  left: 0px;
  overflow: hidden;
  margin-top: -15px;
  box-sizing: border-box;
  color: #333;
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute; }

.gyges .tp-tab-date {
  display: block;
  color: rgba(255, 255, 255, 0.25);
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0px; }

.gyges .tp-tab-title {
  display: block;
  text-align: left;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  line-height: 17px; }

.gyges .tp-tab:hover,
.gyges .tp-tab.selected {
  background: rgba(0, 0, 0, 0.5); }

/* MEDIA QUERIES */
/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 HADES SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
/* ARROWS */
.hades.tparrows {
  cursor: pointer;
  background: #000;
  background: rgba(0, 0, 0, 0.15);
  width: 100px;
  height: 100px;
  position: absolute;
  display: block;
  z-index: 1000; }

.hades.tparrows:before {
  font-family: "revicons";
  font-size: 30px;
  color: #fff;
  display: block;
  line-height: 100px;
  text-align: center;
  transition: background 0.3s, color 0.3s; }

.hades.tparrows.tp-leftarrow:before {
  content: "\e824"; }

.hades.tparrows.tp-rightarrow:before {
  content: "\e825"; }

.hades.tparrows:hover:before {
  color: #aaa;
  background: #fff;
  background: white; }

.hades .tp-arr-allwrapper {
  position: absolute;
  left: 100%;
  top: 0px;
  background: #888;
  width: 100px;
  height: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -ms-filter: "progid:dximagetransform.microsoft.alpha(opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0.0;
  -khtml-opacity: 0.0;
  opacity: 0.0;
  -webkit-transform: rotatey(-90deg);
  transform: rotatey(-90deg);
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%; }

.hades.tp-rightarrow .tp-arr-allwrapper {
  left: auto;
  right: 100%;
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transform: rotatey(90deg);
  transform: rotatey(90deg); }

.hades:hover .tp-arr-allwrapper {
  -ms-filter: "progid:dximagetransform.microsoft.alpha(opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-transform: rotatey(0deg);
  transform: rotatey(0deg); }

.hades .tp-arr-imgholder {
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%; }

/* BULLETS */
.hades.tp-bullets:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
  box-sizing: content-box; }

.hades .tp-bullet {
  width: 3px;
  height: 3px;
  position: absolute;
  background: #888;
  cursor: pointer;
  border: 5px solid #fff;
  box-sizing: content-box;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  -webkit-perspective: 400;
  perspective: 400;
  -webkit-transform: translatez(0.01px);
  transform: translatez(0.01px); }

.hades .tp-bullet:hover,
.hades .tp-bullet.selected {
  background: #555; }

.hades .tp-bullet-image {
  position: absolute;
  top: -80px;
  left: -60px;
  width: 120px;
  height: 60px;
  background-position: center center;
  background-size: cover;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  -webkit-transform-style: flat;
  transform-style: flat;
  perspective: 600;
  -webkit-perspective: 600;
  transform: rotatex(-90deg);
  -webkit-transform: rotatex(-90deg);
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%; }

.hades .tp-bullet:hover .tp-bullet-image {
  display: block;
  opacity: 1;
  transform: rotatex(0deg);
  -webkit-transform: rotatex(0deg);
  visibility: visible; }

/* THUMBS */
.hades .tp-thumb {
  opacity: 1; }

.hades .tp-thumb-img-wrap {
  border-radius: 50%;
  padding: 3px;
  display: inline-block;
  background: #000;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0px;
  box-sizing: border-box;
  transition: all 0.3s;
  -webkit-transition: all 0.3s; }

.hades .tp-thumb-image {
  padding: 3px;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  position: relative;
  -webkit-box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25); }

.hades .tp-thumb:hover .tp-thumb-img-wrap,
.hades .tp-thumb.selected .tp-thumb-img-wrap {
  background: white;
  background: -moz-linear-gradient(top, white 0%, #777777 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, white), color-stop(100%, #777777));
  background: -webkit-linear-gradient(top, white 0%, #777777 100%);
  background: -o-linear-gradient(top, white 0%, #777777 100%);
  background: -ms-linear-gradient(top, white 0%, #777777 100%);
  background: linear-gradient(to bottom, white 0%, #777777 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#777777", GradientType=0 ); }

.hades .tp-thumb-title:after {
  content: " ";
  position: absolute;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
  bottom: -8px; }

/* TABS */
.hades .tp-tab {
  opacity: 1; }

.hades .tp-tab-title {
  display: block;
  color: #333;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  line-height: 25px; }

.hades .tp-tab-price {
  display: block;
  text-align: center;
  color: #999;
  font-size: 16px;
  margin-top: 10px;
  line-height: 20px; }

.hades .tp-tab-button {
  display: inline-block;
  margin-top: 15px;
  text-align: center;
  padding: 5px 15px;
  color: #fff;
  font-size: 14px;
  background: #219bd7;
  border-radius: 4px;
  font-weight: 400; }

.hades .tp-tab-inner {
  text-align: center; }

/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 HEBE SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
/* ARROWS */
.hebe.tparrows {
  cursor: pointer;
  background: #fff;
  min-width: 70px;
  min-height: 70px;
  position: absolute;
  display: block;
  z-index: 1000; }

.hebe.tparrows:before {
  font-family: "revicons";
  font-size: 30px;
  color: #aaa;
  display: block;
  line-height: 70px;
  text-align: center;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
  z-index: 2;
  position: relative;
  background: #fff;
  min-width: 70px;
  min-height: 70px; }

.hebe.tparrows.tp-leftarrow:before {
  content: "\e824"; }

.hebe.tparrows.tp-rightarrow:before {
  content: "\e825"; }

.hebe.tparrows:hover:before {
  color: #000; }

.hebe .tp-title-wrap {
  position: absolute;
  z-index: 0;
  display: inline-block;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
  min-height: 60px;
  line-height: 60px;
  top: -10px;
  margin-left: 0px;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  transform-origin: 0% 50%;
  -webkit-transform-origin: 0% 50%; }

.hebe.tp-rightarrow .tp-title-wrap {
  right: 0px;
  -webkit-transform-origin: 100% 50%; }

.hebe.tparrows:hover .tp-title-wrap {
  transform: scaleX(1);
  -webkit-transform: scaleX(1); }

.hebe .tp-arr-titleholder {
  position: relative;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 90px;
  white-space: nowrap;
  padding: 0px 20px 0px 90px; }

.hebe.tp-rightarrow .tp-arr-titleholder {
  margin-left: 0px;
  padding: 0px 90px 0px 20px; }

.hebe.tparrows:hover .tp-arr-titleholder {
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
  transition-delay: 0.1s;
  opacity: 1; }

.hebe .tp-arr-imgholder {
  width: 90px;
  height: 90px;
  position: absolute;
  left: 100%;
  display: block;
  background-size: cover;
  background-position: center center;
  top: 0px;
  right: -90px; }

.hebe.tp-rightarrow .tp-arr-imgholder {
  right: auto;
  left: -90px; }

/* BULLETS */
.hebe.tp-bullets:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
  box-sizing: content-box; }

.hebe .tp-bullet {
  width: 3px;
  height: 3px;
  position: absolute;
  background: #fff;
  cursor: pointer;
  border: 5px solid #222;
  border-radius: 50%;
  box-sizing: content-box;
  -webkit-perspective: 400;
  perspective: 400;
  -webkit-transform: translateZ(0.01px);
  transform: translateZ(0.01px);
  transition: all 0.3s; }

.hebe .tp-bullet:hover,
.hebe .tp-bullet.selected {
  background: #222;
  border-color: #fff; }

.hebe .tp-bullet-image {
  position: absolute;
  top: -90px;
  left: -40px;
  width: 70px;
  height: 70px;
  background-position: center center;
  background-size: cover;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  -webkit-transform-style: flat;
  transform-style: flat;
  perspective: 600;
  -webkit-perspective: 600;
  transform: scale(0);
  -webkit-transform: scale(0);
  transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  border-radius: 6px; }

.hebe .tp-bullet:hover .tp-bullet-image {
  display: block;
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
  visibility: visible; }

/* TABS */
.hebe .tp-tab-title {
  color: #a8d8ee;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Roboto Slab";
  margin-bottom: 5px; }

.hebe .tp-tab-desc {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  line-height: 25px;
  font-family: "Roboto Slab"; }

/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 HEPHAISTOS SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
/* ARROWS */
.hephaistos.tparrows {
  cursor: pointer;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  position: absolute;
  display: block;
  z-index: 1000;
  border-radius: 50%; }

.hephaistos.tparrows:hover {
  background: #000; }

.hephaistos.tparrows:before {
  font-family: "revicons";
  font-size: 18px;
  color: #fff;
  display: block;
  line-height: 40px;
  text-align: center; }

.hephaistos.tparrows.tp-leftarrow:before {
  content: "\e82c";
  margin-left: -2px; }

.hephaistos.tparrows.tp-rightarrow:before {
  content: "\e82d";
  margin-right: -2px; }

/* BULLETS */
.hephaistos.tp-bullets:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
  box-sizing: content-box; }

.hephaistos .tp-bullet {
  width: 12px;
  height: 12px;
  position: absolute;
  background: #999;
  border: 3px solid #f5f5f5;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: content-box;
  box-shadow: 0px 0px 2px 1px rgba(130, 130, 130, 0.3); }

.hephaistos .tp-bullet:hover,
.hephaistos .tp-bullet.selected {
  background: #fff;
  border-color: #000; }

/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 HERMES SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
/* ARROWS */
.hermes.tparrows {
  cursor: pointer;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 110px;
  position: absolute;
  display: block;
  z-index: 1000; }

.hermes.tparrows:before {
  font-family: "revicons";
  font-size: 15px;
  color: #fff;
  display: block;
  line-height: 110px;
  text-align: center;
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
  transition: all 0.3s;
  -webkit-transition: all 0.3s; }

.hermes.tparrows.tp-leftarrow:before {
  content: "\e824"; }

.hermes.tparrows.tp-rightarrow:before {
  content: "\e825"; }

.hermes.tparrows.tp-leftarrow:hover:before {
  transform: translateX(-20px);
  -webkit-transform: translateX(-20px);
  opacity: 0; }

.hermes.tparrows.tp-rightarrow:hover:before {
  transform: translateX(20px);
  -webkit-transform: translateX(20px);
  opacity: 0; }

.hermes .tp-arr-allwrapper {
  overflow: hidden;
  position: absolute;
  width: 180px;
  height: 140px;
  top: 0px;
  left: 0px;
  visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s;
  -webkit-perspective: 1000px;
  perspective: 1000px; }

.hermes.tp-rightarrow .tp-arr-allwrapper {
  right: 0px;
  left: auto; }

.hermes.tparrows:hover .tp-arr-allwrapper {
  visibility: visible; }

.hermes .tp-arr-imgholder {
  width: 180px;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 110px;
  transform: translateX(-180px);
  -webkit-transform: translateX(-180px);
  transition: all 0.3s;
  transition-delay: 0.3s; }

.hermes.tp-rightarrow .tp-arr-imgholder {
  transform: translateX(180px);
  -webkit-transform: translateX(180px); }

.hermes.tparrows:hover .tp-arr-imgholder {
  transform: translateX(0px);
  -webkit-transform: translateX(0px); }

.hermes .tp-arr-titleholder {
  top: 110px;
  width: 180px;
  text-align: left;
  display: block;
  padding: 0px 10px;
  line-height: 30px;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-weight: 600;
  position: absolute;
  font-size: 12px;
  white-space: nowrap;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  box-sizing: border-box; }

.hermes.tparrows:hover .tp-arr-titleholder {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg); }

/* BULLETS */
.hermes .tp-bullet {
  overflow: hidden;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 0 2px #FFF;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  position: absolute; }

.hermes .tp-bullet:hover {
  background-color: rgba(0, 0, 0, 0.2); }

.hermes .tp-bullet:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  height: 0;
  left: 0;
  width: 100%;
  background-color: #FFF;
  box-shadow: 0 0 1px #FFF;
  -webkit-transition: height 0.3s ease;
  transition: height 0.3s ease; }

.hermes .tp-bullet.selected:after {
  height: 100%; }

/* TABS */
.hermes .tp-tab {
  opacity: 1;
  padding-right: 10px;
  box-sizing: border-box; }

.hermes .tp-tab-image {
  width: 100%;
  height: 60%;
  position: relative; }

.hermes .tp-tab-content {
  background: #363636;
  position: absolute;
  padding: 20px 20px 20px 30px;
  box-sizing: border-box;
  color: #fff;
  display: block;
  width: 100%;
  min-height: 40%;
  bottom: 0px;
  left: -10px; }

.hermes .tp-tab-date {
  display: block;
  color: #888;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 10px; }

.hermes .tp-tab-title {
  display: block;
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 19px; }

.hermes .tp-tab.selected .tp-tab-title:after {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 30px 0 30px 10px;
  border-color: transparent transparent transparent #363636;
  content: " ";
  position: absolute;
  right: -9px;
  bottom: 50%;
  margin-bottom: -30px; }

.hermes .tp-tab-mask {
  padding-right: 10px !important; }

/* MEDIA QUERIES */
@media only screen and (max-width: 960px) {
  .hermes .tp-tab .tp-tab-title {
    font-size: 14px;
    line-height: 16px; }
  .hermes .tp-tab-date {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 10px; }
  .hermes .tp-tab-content {
    padding: 15px 15px 15px 25px; } }

@media only screen and (max-width: 768px) {
  .hermes .tp-tab .tp-tab-title {
    font-size: 12px;
    line-height: 14px; }
  .hermes .tp-tab-date {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 5px; }
  .hermes .tp-tab-content {
    padding: 10px 10px 10px 20px; } }

/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 HESPERIDEN SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
/* ARROWS */
.hesperiden.tparrows {
  cursor: pointer;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  position: absolute;
  display: block;
  z-index: 1000;
  border-radius: 50%; }

.hesperiden.tparrows:hover {
  background: #000; }

.hesperiden.tparrows:before {
  font-family: "revicons";
  font-size: 20px;
  color: #fff;
  display: block;
  line-height: 40px;
  text-align: center; }

.hesperiden.tparrows.tp-leftarrow:before {
  content: "\e82c";
  margin-left: -3px; }

.hesperiden.tparrows.tp-rightarrow:before {
  content: "\e82d";
  margin-right: -3px; }

/* BULLETS */
.hesperiden.tp-bullets:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
  box-sizing: content-box;
  border-radius: 8px; }

.hesperiden .tp-bullet {
  width: 12px;
  height: 12px;
  position: absolute;
  background: #999999;
  /* old browsers */
  background: -moz-linear-gradient(top, #999999 0%, #e1e1e1 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #999999), color-stop(100%, #e1e1e1));
  /* chrome,safari4+ */
  background: -webkit-linear-gradient(top, #999999 0%, #e1e1e1 100%);
  /* chrome10+,safari5.1+ */
  background: -o-linear-gradient(top, #999999 0%, #e1e1e1 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(top, #999999 0%, #e1e1e1 100%);
  /* ie10+ */
  background: linear-gradient(to bottom, #999999 0%, #e1e1e1 100%);
  /* w3c */
  filter: progid:dximagetransform.microsoft.gradient(  startcolorstr="#999999", endcolorstr="#e1e1e1",gradienttype=0 );
  /* ie6-9 */
  border: 3px solid #e5e5e5;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: content-box; }

.hesperiden .tp-bullet:hover,
.hesperiden .tp-bullet.selected {
  background: #666; }

/* THUMBS */
.hesperiden .tp-thumb {
  opacity: 1;
  -webkit-perspective: 600px;
  perspective: 600px; }

.hesperiden .tp-thumb .tp-thumb-title {
  font-size: 12px;
  position: absolute;
  margin-top: -10px;
  color: #fff;
  display: block;
  z-index: 10000;
  background-color: #000;
  padding: 5px 10px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transform: rotatex(90deg) translatez(0.001px);
  transform-origin: 50% 100%;
  -webkit-transform: rotatex(90deg) translatez(0.001px);
  -webkit-transform-origin: 50% 100%;
  opacity: 0; }

.hesperiden .tp-thumb:hover .tp-thumb-title {
  transform: rotatex(0deg);
  -webkit-transform: rotatex(0deg);
  opacity: 1; }

/* TABS */
.hesperiden .tp-tab {
  opacity: 1;
  padding: 10px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  border-bottom: 1px solid #e5e5e5; }

.hesperiden .tp-tab-image {
  width: 60px;
  height: 60px;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  display: inline-block;
  float: left; }

.hesperiden .tp-tab-content {
  background: rgba(0, 0, 0, 0);
  position: relative;
  padding: 15px 15px 15px 85px;
  left: 0px;
  overflow: hidden;
  margin-top: -15px;
  box-sizing: border-box;
  color: #333;
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute; }

.hesperiden .tp-tab-date {
  display: block;
  color: #aaa;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0px; }

.hesperiden .tp-tab-title {
  display: block;
  text-align: left;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  line-height: 17px; }

.hesperiden .tp-tab:hover,
.hesperiden .tp-tab.selected {
  background: #eee; }

/* MEDIA QUERIES */
/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 METIS SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
/* ARROWS */
.metis.tparrows {
  background: #fff;
  padding: 10px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  width: 60px;
  height: 60px;
  box-sizing: border-box; }

.metis.tparrows:hover {
  background: #fff;
  background: rgba(255, 255, 255, 0.75); }

.metis.tparrows:before {
  color: #000;
  transition: all 0.3s;
  -webkit-transition: all 0.3s; }

.metis.tparrows:hover:before {
  transform: scale(1.5); }

/* BULLETS */
.metis .tp-bullet {
  opacity: 1;
  width: 50px;
  height: 50px;
  padding: 3px;
  background: #000;
  background-color: rgba(0, 0, 0, 0.25);
  margin: 0px;
  box-sizing: border-box;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  border-radius: 50%; }

.metis .tp-bullet-image {
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  position: relative;
  -webkit-box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: inset 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  width: 44px;
  height: 44px;
  background-size: cover;
  background-position: center center; }

.metis .tp-bullet-title {
  position: absolute;
  bottom: 65px;
  display: inline-block;
  left: 50%;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 10px 30px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  opacity: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transform: translateZ(0.001px) translateX(-50%) translateY(14px);
  transform-origin: 50% 100%;
  -webkit-transform: translateZ(0.001px) translateX(-50%) translateY(14px);
  -webkit-transform-origin: 50% 100%;
  opacity: 0;
  white-space: nowrap; }

.metis .tp-bullet:hover .tp-bullet-title {
  transform: rotateX(0deg) translateX(-50%);
  -webkit-transform: rotateX(0deg) translateX(-50%);
  opacity: 1; }

.metis .tp-bullet.selected,
.metis .tp-bullet:hover {
  background: white;
  background: -moz-linear-gradient(top, white 0%, #777777 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, white), color-stop(100%, #777777));
  background: -webkit-linear-gradient(top, white 0%, #777777 100%);
  background: -o-linear-gradient(top, white 0%, #777777 100%);
  background: -ms-linear-gradient(top, white 0%, #777777 100%);
  background: linear-gradient(to bottom, white 0%, #777777 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#777777", GradientType=0 ); }

.metis .tp-bullet-title:after {
  content: " ";
  position: absolute;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
  bottom: -8px; }

.metis .tp-tab-number {
  color: #fff;
  font-size: 40px;
  line-height: 30px;
  font-weight: 400;
  font-family: "Playfair Display";
  width: 50px;
  margin-right: 17px;
  display: inline-block;
  float: left; }

.metis .tp-tab-mask {
  padding-left: 20px;
  left: 0px;
  max-width: 90px !important;
  transition: 0.4s padding-left, 0.4s left, 0.4s max-width; }

.metis:hover .tp-tab-mask {
  padding-left: 0px;
  left: 50px;
  max-width: 500px !important; }

.metis .tp-tab-divider {
  border-right: 1px solid transparent;
  height: 30px;
  width: 1px;
  margin-top: 5px;
  display: inline-block;
  float: left; }

.metis .tp-tab-title {
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Playfair Display";
  position: relative;
  padding-top: 10px;
  padding-left: 30px;
  display: inline-block;
  transform: translateX(-100%);
  transition: 0.4s all; }

.metis .tp-tab-title-mask {
  position: absolute;
  overflow: hidden;
  left: 67px; }

.metis:hover .tp-tab-title {
  transform: translateX(0); }

.metis .tp-tab {
  opacity: 0.15;
  transition: 0.4s all; }

.metis .tp-tab:hover,
.metis .tp-tab.selected {
  opacity: 1; }

.metis .tp-tab.selected .tp-tab-divider {
  border-right: 1px solid #f53700; }

.metis.tp-tabs {
  max-width: 118px !important;
  padding-left: 50px; }

.metis.tp-tabs:before {
  content: " ";
  height: 100%;
  width: 88px;
  background: rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  left: 0px;
  top: 0px;
  position: absolute;
  transition: 0.4s all; }

.metis.tp-tabs:hover:before {
  width: 118px; }

@media (max-width: 499px) {
  .metis.tp-tabs:before {
    background: rgba(0, 0, 0, 0.75); } }

/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 PERSEPHONE SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
/* ARROWS */
.persephone.tparrows {
  cursor: pointer;
  background: #aaa;
  background: rgba(200, 200, 200, 0.5);
  width: 40px;
  height: 40px;
  position: absolute;
  display: block;
  z-index: 100;
  border: 1px solid #f5f5f5; }

.persephone.tparrows:hover {
  background: #333; }

.persephone.tparrows:before {
  font-family: "revicons";
  font-size: 15px;
  color: #fff;
  display: block;
  line-height: 40px;
  text-align: center; }

.persephone.tparrows.tp-leftarrow:before {
  content: "\e824"; }

.persephone.tparrows.tp-rightarrow:before {
  content: "\e825"; }

/* BULLETS */
.persephone.tp-bullets:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #transparent;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
  box-sizing: content-box; }

.persephone .tp-bullet {
  width: 12px;
  height: 12px;
  position: absolute;
  background: #aaa;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  box-sizing: content-box; }

.persephone .tp-bullet:hover,
.persephone .tp-bullet.selected {
  background: #222; }

/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 URANUS SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
/* ARROWS */
.uranus.tparrows {
  width: 50px;
  height: 50px;
  background: transparent; }

.uranus.tparrows:before {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 40px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s; }

.uranus.tparrows:hover:before {
  opacity: 0.75; }

/* BULLETS */
.uranus .tp-bullet {
  border-radius: 50%;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  background: transparent; }

.uranus .tp-bullet.selected,
.uranus .tp-bullet:hover {
  box-shadow: 0 0 0 2px #FFF;
  border: none;
  border-radius: 50%;
  background: transparent; }

.uranus .tp-bullet-inner {
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
  transition: background-color 0.3s ease, transform 0.3s ease;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 50%;
  background-color: #FFF;
  background-color: rgba(255, 255, 255, 0.3);
  text-indent: -999em;
  cursor: pointer;
  position: absolute; }

.uranus .tp-bullet.selected .tp-bullet-inner,
.uranus .tp-bullet:hover .tp-bullet-inner {
  transform: scale(0.4);
  -webkit-transform: scale(0.4);
  background-color: #fff; }

/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 ZEUS SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
/* ARROWS */
.zeus.tparrows {
  cursor: pointer;
  min-width: 70px;
  min-height: 70px;
  position: absolute;
  display: block;
  z-index: 100;
  border-radius: 35px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1); }

.zeus.tparrows:before {
  font-family: "revicons";
  font-size: 20px;
  color: #fff;
  display: block;
  line-height: 70px;
  text-align: center;
  z-index: 2;
  position: relative; }

.zeus.tparrows.tp-leftarrow:before {
  content: "\e824"; }

.zeus.tparrows.tp-rightarrow:before {
  content: "\e825"; }

.zeus .tp-title-wrap {
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  opacity: 0;
  transform: scale(0);
  -webkit-transform: scale(0);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  border-radius: 50%; }

.zeus .tp-arr-imgholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s; }

.zeus.tp-rightarrow .tp-arr-imgholder {
  transform: translateX(100%);
  -webkit-transform: translateX(100%); }

.zeus.tparrows:hover .tp-arr-imgholder {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  opacity: 1; }

.zeus.tparrows:hover .tp-title-wrap {
  transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1; }

/* BULLETS */
.zeus .tp-bullet {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  width: 13px;
  height: 13px;
  border: 2px solid #fff; }

.zeus .tp-bullet:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 50%;
  background-color: #FFF;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease; }

.zeus .tp-bullet:hover:after,
.zeus .tp-bullet.selected:after {
  -webkit-transform: scale(1.2);
  transform: scale(1.2); }

.zeus .tp-bullet-image,
.zeus .tp-bullet-imageoverlay {
  width: 135px;
  height: 60px;
  position: absolute;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  bottom: 25px;
  left: 50%;
  margin-left: -65px;
  box-sizing: border-box;
  background-size: cover;
  background-position: center center;
  visibility: hidden;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 4px; }

.zeus .tp-bullet-title,
.zeus .tp-bullet-imageoverlay {
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease; }

.zeus .tp-bullet-title {
  color: #fff;
  text-align: center;
  line-height: 15px;
  font-size: 13px;
  font-weight: 600;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  bottom: 45px;
  width: 135px;
  vertical-align: middle;
  left: -57px; }

.zeus .tp-bullet:hover .tp-bullet-title,
.zeus .tp-bullet:hover .tp-bullet-image,
.zeus .tp-bullet:hover .tp-bullet-imageoverlay {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  transform: translateY(0px); }

/* THUMBS */
.zeus .tp-thumb {
  opacity: 1; }

.zeus .tp-thumb-over {
  background: #000;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.zeus .tp-thumb-more:before {
  font-family: "revicons";
  font-size: 12px;
  color: #aaa;
  color: rgba(255, 255, 255, 0.75);
  display: block;
  line-height: 12px;
  text-align: left;
  z-index: 2;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2; }

.zeus .tp-thumb-more:before {
  content: "\e825"; }

.zeus .tp-thumb-title {
  font-family: "Raleway";
  letter-spacing: 1px;
  font-size: 12px;
  color: #fff;
  display: block;
  line-height: 15px;
  text-align: left;
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  padding: 20px 35px 20px 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  font-weight: 500; }

.zeus .tp-thumb.selected .tp-thumb-more:before,
.zeus .tp-thumb:hover .tp-thumb-more:before {
  color: #aaa; }

.zeus .tp-thumb.selected .tp-thumb-over,
.zeus .tp-thumb:hover .tp-thumb-over {
  background: #000; }

.zeus .tp-thumb.selected .tp-thumb-title,
.zeus .tp-thumb:hover .tp-thumb-title {
  color: #fff; }

/* TABS */
.zeus .tp-tab {
  opacity: 1;
  box-sizing: border-box; }

.zeus .tp-tab-title {
  display: block;
  text-align: center;
  background: rgba(0, 0, 0, 0.25);
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  color: #fff;
  padding: 9px 10px; }

.zeus .tp-tab:hover .tp-tab-title,
.zeus .tp-tab.selected .tp-tab-title {
  color: #000;
  background: white; }

/*-----------------------------------------------------------------------------

- Revolution Slider 5.0 Navigatin Skin Style  -

 ZEUS SKIN

author:  ThemePunch
email:      info@themepunch.com
website:    http://www.themepunch.com
-----------------------------------------------------------------------------*/
.post-tabs .tp-thumb {
  opacity: 1; }

.post-tabs .tp-thumb-over {
  background: #252525;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.post-tabs .tp-thumb-more:before {
  font-family: "revicons";
  font-size: 12px;
  color: #aaa;
  color: rgba(255, 255, 255, 0.75);
  display: block;
  line-height: 12px;
  text-align: left;
  z-index: 2;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2; }

.post-tabs .tp-thumb-more:before {
  content: "\e825"; }

.post-tabs .tp-thumb-title {
  font-family: "raleway";
  letter-spacing: 1px;
  font-size: 12px;
  color: #fff;
  display: block;
  line-height: 15px;
  text-align: left;
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  padding: 15px 30px 15px 15px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  font-weight: 500; }

.post-tabs .tp-thumb.selected .tp-thumb-more:before,
.post-tabs .tp-thumb:hover .tp-thumb-more:before {
  color: #aaa; }

.post-tabs .tp-thumb.selected .tp-thumb-over,
.post-tabs .tp-thumb:hover .tp-thumb-over {
  background: #fff; }

.post-tabs .tp-thumb.selected .tp-thumb-title,
.post-tabs .tp-thumb:hover .tp-thumb-title {
  color: #000; }

@font-face {
  font-family: 'Pe-icon-7-stroke';
  src: url("../fonts/Pe-icon-7-stroke.eot?d7yf1v");
  src: url("../fonts/Pe-icon-7-stroke.eot?#iefixd7yf1v") format("embedded-opentype"), url("../fonts/Pe-icon-7-stroke.woff?d7yf1v") format("woff"), url("../fonts/Pe-icon-7-stroke.ttf?d7yf1v") format("truetype"), url("../fonts/Pe-icon-7-stroke.svg?d7yf1v#Pe-icon-7-stroke") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="pe-7s-"], [class*=" pe-7s-"] {
  display: inline-block;
  font-family: 'Pe-icon-7-stroke';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.pe-7s-album:before {
  content: "\e6aa"; }

.pe-7s-arc:before {
  content: "\e6ab"; }

.pe-7s-back-2:before {
  content: "\e6ac"; }

.pe-7s-bandaid:before {
  content: "\e6ad"; }

.pe-7s-car:before {
  content: "\e6ae"; }

.pe-7s-diamond:before {
  content: "\e6af"; }

.pe-7s-door-lock:before {
  content: "\e6b0"; }

.pe-7s-eyedropper:before {
  content: "\e6b1"; }

.pe-7s-female:before {
  content: "\e6b2"; }

.pe-7s-gym:before {
  content: "\e6b3"; }

.pe-7s-hammer:before {
  content: "\e6b4"; }

.pe-7s-headphones:before {
  content: "\e6b5"; }

.pe-7s-helm:before {
  content: "\e6b6"; }

.pe-7s-hourglass:before {
  content: "\e6b7"; }

.pe-7s-leaf:before {
  content: "\e6b8"; }

.pe-7s-magic-wand:before {
  content: "\e6b9"; }

.pe-7s-male:before {
  content: "\e6ba"; }

.pe-7s-map-2:before {
  content: "\e6bb"; }

.pe-7s-next-2:before {
  content: "\e6bc"; }

.pe-7s-paint-bucket:before {
  content: "\e6bd"; }

.pe-7s-pendrive:before {
  content: "\e6be"; }

.pe-7s-photo:before {
  content: "\e6bf"; }

.pe-7s-piggy:before {
  content: "\e6c0"; }

.pe-7s-plugin:before {
  content: "\e6c1"; }

.pe-7s-refresh-2:before {
  content: "\e6c2"; }

.pe-7s-rocket:before {
  content: "\e6c3"; }

.pe-7s-settings:before {
  content: "\e6c4"; }

.pe-7s-shield:before {
  content: "\e6c5"; }

.pe-7s-smile:before {
  content: "\e6c6"; }

.pe-7s-usb:before {
  content: "\e6c7"; }

.pe-7s-vector:before {
  content: "\e6c8"; }

.pe-7s-wine:before {
  content: "\e6c9"; }

.pe-7s-cloud-upload:before {
  content: "\e68a"; }

.pe-7s-cash:before {
  content: "\e68c"; }

.pe-7s-close:before {
  content: "\e680"; }

.pe-7s-bluetooth:before {
  content: "\e68d"; }

.pe-7s-cloud-download:before {
  content: "\e68b"; }

.pe-7s-way:before {
  content: "\e68e"; }

.pe-7s-close-circle:before {
  content: "\e681"; }

.pe-7s-id:before {
  content: "\e68f"; }

.pe-7s-angle-up:before {
  content: "\e682"; }

.pe-7s-wristwatch:before {
  content: "\e690"; }

.pe-7s-angle-up-circle:before {
  content: "\e683"; }

.pe-7s-world:before {
  content: "\e691"; }

.pe-7s-angle-right:before {
  content: "\e684"; }

.pe-7s-volume:before {
  content: "\e692"; }

.pe-7s-angle-right-circle:before {
  content: "\e685"; }

.pe-7s-users:before {
  content: "\e693"; }

.pe-7s-angle-left:before {
  content: "\e686"; }

.pe-7s-user-female:before {
  content: "\e694"; }

.pe-7s-angle-left-circle:before {
  content: "\e687"; }

.pe-7s-up-arrow:before {
  content: "\e695"; }

.pe-7s-angle-down:before {
  content: "\e688"; }

.pe-7s-switch:before {
  content: "\e696"; }

.pe-7s-angle-down-circle:before {
  content: "\e689"; }

.pe-7s-scissors:before {
  content: "\e697"; }

.pe-7s-wallet:before {
  content: "\e600"; }

.pe-7s-safe:before {
  content: "\e698"; }

.pe-7s-volume2:before {
  content: "\e601"; }

.pe-7s-volume1:before {
  content: "\e602"; }

.pe-7s-voicemail:before {
  content: "\e603"; }

.pe-7s-video:before {
  content: "\e604"; }

.pe-7s-user:before {
  content: "\e605"; }

.pe-7s-upload:before {
  content: "\e606"; }

.pe-7s-unlock:before {
  content: "\e607"; }

.pe-7s-umbrella:before {
  content: "\e608"; }

.pe-7s-trash:before {
  content: "\e609"; }

.pe-7s-tools:before {
  content: "\e60a"; }

.pe-7s-timer:before {
  content: "\e60b"; }

.pe-7s-ticket:before {
  content: "\e60c"; }

.pe-7s-target:before {
  content: "\e60d"; }

.pe-7s-sun:before {
  content: "\e60e"; }

.pe-7s-study:before {
  content: "\e60f"; }

.pe-7s-stopwatch:before {
  content: "\e610"; }

.pe-7s-star:before {
  content: "\e611"; }

.pe-7s-speaker:before {
  content: "\e612"; }

.pe-7s-signal:before {
  content: "\e613"; }

.pe-7s-shuffle:before {
  content: "\e614"; }

.pe-7s-shopbag:before {
  content: "\e615"; }

.pe-7s-share:before {
  content: "\e616"; }

.pe-7s-server:before {
  content: "\e617"; }

.pe-7s-search:before {
  content: "\e618"; }

.pe-7s-film:before {
  content: "\e6a5"; }

.pe-7s-science:before {
  content: "\e619"; }

.pe-7s-disk:before {
  content: "\e6a6"; }

.pe-7s-ribbon:before {
  content: "\e61a"; }

.pe-7s-repeat:before {
  content: "\e61b"; }

.pe-7s-refresh:before {
  content: "\e61c"; }

.pe-7s-add-user:before {
  content: "\e6a9"; }

.pe-7s-refresh-cloud:before {
  content: "\e61d"; }

.pe-7s-paperclip:before {
  content: "\e69c"; }

.pe-7s-radio:before {
  content: "\e61e"; }

.pe-7s-note2:before {
  content: "\e69d"; }

.pe-7s-print:before {
  content: "\e61f"; }

.pe-7s-network:before {
  content: "\e69e"; }

.pe-7s-prev:before {
  content: "\e620"; }

.pe-7s-mute:before {
  content: "\e69f"; }

.pe-7s-power:before {
  content: "\e621"; }

.pe-7s-medal:before {
  content: "\e6a0"; }

.pe-7s-portfolio:before {
  content: "\e622"; }

.pe-7s-like2:before {
  content: "\e6a1"; }

.pe-7s-plus:before {
  content: "\e623"; }

.pe-7s-left-arrow:before {
  content: "\e6a2"; }

.pe-7s-play:before {
  content: "\e624"; }

.pe-7s-key:before {
  content: "\e6a3"; }

.pe-7s-plane:before {
  content: "\e625"; }

.pe-7s-joy:before {
  content: "\e6a4"; }

.pe-7s-photo-gallery:before {
  content: "\e626"; }

.pe-7s-pin:before {
  content: "\e69b"; }

.pe-7s-phone:before {
  content: "\e627"; }

.pe-7s-plug:before {
  content: "\e69a"; }

.pe-7s-pen:before {
  content: "\e628"; }

.pe-7s-right-arrow:before {
  content: "\e699"; }

.pe-7s-paper-plane:before {
  content: "\e629"; }

.pe-7s-delete-user:before {
  content: "\e6a7"; }

.pe-7s-paint:before {
  content: "\e62a"; }

.pe-7s-bottom-arrow:before {
  content: "\e6a8"; }

.pe-7s-notebook:before {
  content: "\e62b"; }

.pe-7s-note:before {
  content: "\e62c"; }

.pe-7s-next:before {
  content: "\e62d"; }

.pe-7s-news-paper:before {
  content: "\e62e"; }

.pe-7s-musiclist:before {
  content: "\e62f"; }

.pe-7s-music:before {
  content: "\e630"; }

.pe-7s-mouse:before {
  content: "\e631"; }

.pe-7s-more:before {
  content: "\e632"; }

.pe-7s-moon:before {
  content: "\e633"; }

.pe-7s-monitor:before {
  content: "\e634"; }

.pe-7s-micro:before {
  content: "\e635"; }

.pe-7s-menu:before {
  content: "\e636"; }

.pe-7s-map:before {
  content: "\e637"; }

.pe-7s-map-marker:before {
  content: "\e638"; }

.pe-7s-mail:before {
  content: "\e639"; }

.pe-7s-mail-open:before {
  content: "\e63a"; }

.pe-7s-mail-open-file:before {
  content: "\e63b"; }

.pe-7s-magnet:before {
  content: "\e63c"; }

.pe-7s-loop:before {
  content: "\e63d"; }

.pe-7s-look:before {
  content: "\e63e"; }

.pe-7s-lock:before {
  content: "\e63f"; }

.pe-7s-lintern:before {
  content: "\e640"; }

.pe-7s-link:before {
  content: "\e641"; }

.pe-7s-like:before {
  content: "\e642"; }

.pe-7s-light:before {
  content: "\e643"; }

.pe-7s-less:before {
  content: "\e644"; }

.pe-7s-keypad:before {
  content: "\e645"; }

.pe-7s-junk:before {
  content: "\e646"; }

.pe-7s-info:before {
  content: "\e647"; }

.pe-7s-home:before {
  content: "\e648"; }

.pe-7s-help2:before {
  content: "\e649"; }

.pe-7s-help1:before {
  content: "\e64a"; }

.pe-7s-graph3:before {
  content: "\e64b"; }

.pe-7s-graph2:before {
  content: "\e64c"; }

.pe-7s-graph1:before {
  content: "\e64d"; }

.pe-7s-graph:before {
  content: "\e64e"; }

.pe-7s-global:before {
  content: "\e64f"; }

.pe-7s-gleam:before {
  content: "\e650"; }

.pe-7s-glasses:before {
  content: "\e651"; }

.pe-7s-gift:before {
  content: "\e652"; }

.pe-7s-folder:before {
  content: "\e653"; }

.pe-7s-flag:before {
  content: "\e654"; }

.pe-7s-filter:before {
  content: "\e655"; }

.pe-7s-file:before {
  content: "\e656"; }

.pe-7s-expand1:before {
  content: "\e657"; }

.pe-7s-exapnd2:before {
  content: "\e658"; }

.pe-7s-edit:before {
  content: "\e659"; }

.pe-7s-drop:before {
  content: "\e65a"; }

.pe-7s-drawer:before {
  content: "\e65b"; }

.pe-7s-download:before {
  content: "\e65c"; }

.pe-7s-display2:before {
  content: "\e65d"; }

.pe-7s-display1:before {
  content: "\e65e"; }

.pe-7s-diskette:before {
  content: "\e65f"; }

.pe-7s-date:before {
  content: "\e660"; }

.pe-7s-cup:before {
  content: "\e661"; }

.pe-7s-culture:before {
  content: "\e662"; }

.pe-7s-crop:before {
  content: "\e663"; }

.pe-7s-credit:before {
  content: "\e664"; }

.pe-7s-copy-file:before {
  content: "\e665"; }

.pe-7s-config:before {
  content: "\e666"; }

.pe-7s-compass:before {
  content: "\e667"; }

.pe-7s-comment:before {
  content: "\e668"; }

.pe-7s-coffee:before {
  content: "\e669"; }

.pe-7s-cloud:before {
  content: "\e66a"; }

.pe-7s-clock:before {
  content: "\e66b"; }

.pe-7s-check:before {
  content: "\e66c"; }

.pe-7s-chat:before {
  content: "\e66d"; }

.pe-7s-cart:before {
  content: "\e66e"; }

.pe-7s-camera:before {
  content: "\e66f"; }

.pe-7s-call:before {
  content: "\e670"; }

.pe-7s-calculator:before {
  content: "\e671"; }

.pe-7s-browser:before {
  content: "\e672"; }

.pe-7s-box2:before {
  content: "\e673"; }

.pe-7s-box1:before {
  content: "\e674"; }

.pe-7s-bookmarks:before {
  content: "\e675"; }

.pe-7s-bicycle:before {
  content: "\e676"; }

.pe-7s-bell:before {
  content: "\e677"; }

.pe-7s-battery:before {
  content: "\e678"; }

.pe-7s-ball:before {
  content: "\e679"; }

.pe-7s-back:before {
  content: "\e67a"; }

.pe-7s-attention:before {
  content: "\e67b"; }

.pe-7s-anchor:before {
  content: "\e67c"; }

.pe-7s-albums:before {
  content: "\e67d"; }

.pe-7s-alarm:before {
  content: "\e67e"; }

.pe-7s-airplay:before {
  content: "\e67f"; }

/* @import url("../vendor/revolution/fonts/font-awesome/css/font-awesome.min.css"); */
